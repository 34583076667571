import React, { createContext, useState } from 'react';
import logger from '../../logger';

export const LoadingContext = createContext();

export const LoadingProvider = (props) => {
  const [activities, setActivities] = useState([]);

  const startActivity = (activityName, activityMessage) => {
    // Set state based on previous state if calls came in quickly in between
    setActivities((previousActivities) => {
      // Filter out all activities that are NOT the targeted one
      let newActivities = previousActivities.filter((activity) => activity.name !== activityName);

      // Add (or replace if it was removed in the filter above) the target activity
      newActivities.push({
        name: activityName,
        message: activityMessage
      });

      logger('startActivity', activityName, newActivities, previousActivities);
      return newActivities;
    });
  };

  const stopActivity = (activityName) => {
    // Set state based on previous state if calls came in quickly in between
    setActivities((previousActivities) => {
      // Filter out all activities that are NOT the targeted one
      let newActivities = previousActivities.filter((activity) => activity.name !== activityName);

      logger('stopActivity', activityName, previousActivities, newActivities);
      return newActivities;
    });
  };

  const state = {
    activities: activities,
    startActivity: startActivity,
    stopActivity: stopActivity
  };

  return <LoadingContext.Provider value={state}>{props.children}</LoadingContext.Provider>;
};
export default LoadingContext;
