import { getDaysArray } from './Datestring';

export function getCarbonForCategories(item, categoriesSelected) {
  let totalCarbon = 0;
  // get the keys of the selected categories filter
  const categoriesList = categoriesSelected
    .map((category) => category.id !== 'Energy' && category.id)
    .filter((item) => item);

  // loop through the the received response from backend in comparison with categories selected
  const valueofcategories = Object.entries(item).map(([key, value]) =>
    categoriesList.includes(key) ? value : null
  );

  // if all the values are null, return total carbon as null
  if (valueofcategories.every((value) => value === null)) {
    return null;
  } else {
    // else  add up the values to get the sum
    categoriesList.map((key) => {
      if (item[key] !== null) {
        totalCarbon = totalCarbon + +item[key];
      }
    });
    return totalCarbon;
  }
}

export const fillMissingDates = (apiResponse) => {
  if (apiResponse.Data) {
    let dayList = getDaysArray(apiResponse.Period.Start, apiResponse.Period.End);
    dayList.forEach((day) => {
      let foundIndex = apiResponse.Data.findIndex((e) => e.Period == day);
      if (foundIndex < 0) {
        apiResponse.Data.push(emptyEmissionDataPoint(day));
      }
    });
  }
};

const emptyEmissionDataPoint = (day) => {
  return {
    Period: day,
    Carbon: null,
    CarbonCompute: null,
    CarbonNetwork: null,
    CarbonStorage: null,
    CarbonMemory: null,
    CarbonEmbodied: null,
    Energy: null
  };
};
