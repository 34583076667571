import React, { useContext, useRef } from 'react';
import LoadingContext from '../../contexts/common/LoadingContext';
import config from './../../config';
import { TdsButton, TdsDivider } from '@scania/tegel-react';
import { styled } from 'styled-components';

const FullHeight = styled.div`
  height: calc(100vh - 62px - var(--scania-footer-height));

  display: flex;
  flex-grow: 1;
`;

function DemoLoginPanel() {
  const loading = useRef(useContext(LoadingContext));
  const handleStartDemoClick = (e) => {
    e.preventDefault();
    loading.current.startActivity('DemoSignOn', 'Preparing demo environment');
    window.location.href = config.OAUTH_REDIRECT_SIGNED_IN;
  };

  return (
    <FullHeight className="tds-container tds-u-flex tds-u-flex-dir-col tds-u-align-center tds-u-justify-center">
      <h2>Welcome to Cloud Emissions Tracker</h2>
      <p className={'tds-u-textalign-center'}>
        This demo will give you some insights in how Cloud Emissions Tracker looks, feels and works.
        <br />
        All content in this demo is real data that has been anonymized.
        <br />
        <br />
        <TdsDivider />
      </p>
      <p>Start by pressing the button below:</p>
      <></>
      <TdsButton
        variant="primary"
        onClick={handleStartDemoClick.bind(this)}
        text={'Enter Cloud Emissions Tracker demo'}></TdsButton>
    </FullHeight>
  );
}

export default DemoLoginPanel;
