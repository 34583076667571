import { TdsNavigationTab, TdsNavigationTabs } from '@scania/tegel-react';
import { Link, Outlet } from 'react-router-dom';
import React from 'react';

function Developers() {
  return (
    <div>
      <TdsNavigationTabs defaultSelectedIndex={0}>
        <TdsNavigationTab>
          <Link to={''}>Introduction</Link>
        </TdsNavigationTab>
        <TdsNavigationTab>
          <Link to={'authentication'}>Authentication</Link>
        </TdsNavigationTab>
        <TdsNavigationTab>
          <Link to={'data'}>Data API</Link>
        </TdsNavigationTab>
        <TdsNavigationTab>
          <Link to={'access'}>Access API</Link>
        </TdsNavigationTab>
      </TdsNavigationTabs>

      <div className={'tds-container'}>
        <div className={'tds-row'}>
          <div className={'tds-col'}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Developers;
