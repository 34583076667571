import React, { createContext, useEffect, useState, useContext } from 'react';
import UserContext from '../Auth/UserContext';

export const CustomersContext = createContext();

export const CustomersProvider = (props) => {
  const [customers, setCustomers] = useState(undefined);
  const [activeCustomer, setActiveCustomer] = useState(undefined);
  const userContext = useContext(UserContext);

  useEffect(() => {
    const getCustomers = () => {
      if (userContext.associatedCustomerIds && userContext.associatedCustomerIds.length > 0) {
        setCustomers(userContext.associatedCustomerIds);
        setActiveCustomer(userContext.associatedCustomerIds[0]);
      }
      if (userContext.associatedCustomerIds && userContext.associatedCustomerIds.length === 0) {
        setActiveCustomer(null);
      }
    };

    getCustomers();
  }, [userContext.associatedCustomerIds]);

  const state = {
    customers: customers,
    activeCustomer: activeCustomer
  };

  return <CustomersContext.Provider value={state}>{props.children}</CustomersContext.Provider>;
};

export default CustomersContext;
