import React, { useContext, useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import './ApiKeysTable.css';
import ApiUserContext from '../../../contexts/Auth/ApiUserContext';
import { format } from 'date-fns';
import { TdsButton } from '@scania/tegel-react';

const ApiKeysTable = () => {
  const [rowData, setRowData] = useState(undefined);
  const [colDefs, setColDefs] = useState(undefined);
  const apiUserContext = useContext(ApiUserContext);
  const gridRefApi = useRef();

  useEffect(() => {
    const getGridData = () => {
      let data = [];
      if (apiUserContext.apiUserOne) {
        data.push({
          RevokeFunction: apiUserContext.deleteApiUserOne,
          ...apiUserContext.apiUserOne
        });
      }
      if (apiUserContext.apiUserTwo) {
        data.push({
          RevokeFunction: apiUserContext.deleteApiUserTwo,
          ...apiUserContext.apiUserTwo
        });
      }

      data.sort((a, b) => a.Id.split('_')[1] - b.Id.split('_')[1]);
      return data;
    };

    setRowData(
      getGridData().map((item) => {
        return {
          apiKey: `API Key #${item.Id.split('_')[1]}`,
          createdAt: createdAt(item.CreatedDateUtc),
          revokeButton: item.RevokeFunction
        };
      })
    );
    // if(apiUserContext.apiUserOne && apiUserContext.apiUserTwo){

    //   setRowData([{apiKey:'API Key #1', createdAt:apiUserContext.apiUserOne.CreatedDateUtc, revokeButton: 'Revoke'}]);
    // }

    setColDefs([
      {
        headerName: 'Key',
        field: 'apiKey',
        flex: 2,
        suppressMovable: true,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '1.5rem'
        }
      },
      {
        headerName: 'Created At',
        field: 'createdAt',
        flex: 5,
        suppressMovable: true,
        cellRenderer: createdAtCellRenderer,
        cellStyle: {
          display: 'flex',
          justifyContent: 'start',
          marginRight: '5',
          alignItems: 'center'
        }
      },
      {
        headerName: 'Revoke your key',
        field: 'revokeButton',
        flex: 2,
        suppressMovable: true,
        cellStyle: {
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          paddingRight: '1.5rem'
        },
        cellRenderer: RevokeButtonCellRenderer
      }
    ]);
  }, [apiUserContext.apiUserOne, apiUserContext.apiUserTwo]);

  const createdAt = (value) => {
    const formatedDate = format(new Date(value), 'MMMM d, yyyy hh:mm:ss');
    return formatedDate;
  };

  const RevokeButtonCellRenderer = (props) => {
    // console.log(props.value);
    return (
      <div title="Revoke your API key">
        <TdsButton onClick={props.value} size="md" text="Revoke" variant="danger"></TdsButton>
      </div>
    );
  };

  const createdAtCellRenderer = (props) => {
    return (
      <div>
        <span className="api-key-table-created-text-span">Created </span>
        <span className="ms-1">{props.value} (UTC)</span>
      </div>
    );
  };
  const rowHeight = 75;
  const rowClass = 'api-key-row-class';
  return (
    <div className="ag-theme-alpine flex-grow-1 api-key-table">
      <AgGridReact
        ref={gridRefApi}
        rowData={rowData}
        columnDefs={colDefs}
        rowHeight={rowHeight}
        rowClass={rowClass}
        domLayout="autoHeight"
      />
    </div>
  );
};

export default ApiKeysTable;
