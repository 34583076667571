import React from 'react';

const SectionLayout = ({ header, children }) => {
  return (
    <>
      <h3>{header}</h3>
      {children}
    </>
  );
};

export default SectionLayout;
