import React from 'react';
import './FooterPanel.css';
import { TdsFooter, TdsFooterGroup, TdsFooterItem } from '@scania/tegel-react';
import { Link } from 'react-router-dom';

function FooterPanel() {
  const scania_privacy_policy =
    'https://www.scania.com/group/en/home/admin/misc/privacy-statement.html';

  return (
    <div className="footer-panel">
      <TdsFooter>
        <div slot="start">
          <TdsFooterGroup>
            <TdsFooterItem>
              <Link
                className="footer-panel-link"
                to="#"
                onClick={(e) => {
                  window.location = 'mailto:green.it@scania.com';
                  e.preventDefault();
                }}>
                Contact Us
              </Link>
            </TdsFooterItem>
            <TdsFooterItem>
              <Link
                className="footer-panel-link"
                to="#"
                onClick={(e) => {
                  window.open(scania_privacy_policy, '_blank');
                  e.preventDefault();
                }}>
                Privacy Statement
              </Link>
            </TdsFooterItem>
          </TdsFooterGroup>
        </div>
      </TdsFooter>
    </div>
  );
}

export default FooterPanel;
