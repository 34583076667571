import React from 'react';
import LineChart from '../../features/Charts/LineChart/LineChart';
import EmissionsContent from '../../components/organisms/EmissionsContent/EmissionsContent';
import EmissionsFilter from './Components/EmissionsFilter';

function Emissions() {
  return (
    <EmissionsContent>
      <EmissionsFilter />
      <LineChart />
    </EmissionsContent>
  );
}

export default Emissions;
