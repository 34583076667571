import { createContext } from 'react';
import FilterContextBase from './FilterContextBase';

export const ProvidersFilterContext = createContext();

export class ProvidersFilterContextProvider extends FilterContextBase {
  // Constructor
  constructor(props) {
    super(props, ProvidersFilterContext, Object.keys({ ProvidersFilterContext })[0]);

    // Define default state (extended from parent) - Using "incorrect" way of setting state (not using "setState") is OK since it's being defined in constructor
    this.state = { ...this.state, ...{ isFetchingItems: true, fetchItems: this.fetchItems } };
  }

  fetchItems = () => {
    this.setState({ isFetchingItems: true });

    this.setItems([
      {
        id: 'AWS',
        label: 'AWS',
        subLabel: 'Amazon Web Services'
      },
      {
        id: 'AZURE',
        label: 'Azure',
        subLabel: 'Microsoft Azure'
      }
    ]);

    this.setState({ isFetchingItems: false });
  };
}

// Set default properties
ProvidersFilterContextProvider.defaultProps = {
  children: null
};

export default ProvidersFilterContext;
