import React from 'react';
import { TdsButton, TdsIcon } from '@scania/tegel-react';

function FilterDropdownButton({
  showAsOriginal,
  itemTypeLabel,
  displayDropdown,
  onClickDropdownHandler
}) {
  return (
    <>
      <TdsButton
        variant="secondary"
        fullbleed
        size={'sm'}
        onClick={onClickDropdownHandler.bind(this)}>
        <div slot={'label'} className="tds-u-flex tds-u-align-center tds-u-gap3">
          <span className="tds-u-mr-auto flex-grow-1 tds-u-text align-start ">
            {!showAsOriginal ? <>Select {itemTypeLabel}</> : <>{itemTypeLabel}</>}
          </span>

          <span>
            <span title="Expand" className={displayDropdown ? 'd-none' : ''}>
              <TdsIcon name="chevron_down" size="16" />
            </span>
            <span title="Collapse" className={displayDropdown ? '' : 'd-none'}>
              <TdsIcon name="chevron_up" size="16" />
            </span>
          </span>
        </div>
      </TdsButton>
    </>
  );
}

// Set default properties
// FilterDropdownButton.defaultProps = {
//   itemTypeLabel: 'items',
//   onClickDropdownHandler: () => {}
// };

export default FilterDropdownButton;
