import React from 'react';
import { TdsAccordion } from '@scania/tegel-react';

const Accordion = ({ children }) => {
  return (
    <div>
      <TdsAccordion>{children}</TdsAccordion>
    </div>
  );
};

export default Accordion;
