import React, { createContext, useEffect, useState, useContext } from 'react';
import { API } from 'aws-amplify';
import logger from '../../logger';
import ServicesFilterContext from '../FilterContexts/ServicesFilterContext';
import { getDateString } from '../../utilities/Datestring';

export const DetailedEmissionsContext = createContext();

export const DetailedEmissionsProvider = (props) => {
  const [provider, setProvider] = useState(undefined);
  const [accountIds, setAccountIds] = useState(undefined);
  const [date, setDate] = useState(undefined);
  const [customerId, setCustomerId] = useState(undefined);
  const [details, setDetails] = useState(undefined);
  const [isTooManyAccounts, setIsTooManyAccounts] = useState(false);
  const [maxNoAccounts] = useState(10);
  const servicesFilterContext = useContext(ServicesFilterContext);

  useEffect(() => {
    // Fetch data first after grid is ready
    if (props.gridReady === true) {
      getDetailedEmissions();
    }
  }, [props.gridReady]);

  const getDetailedEmissions = () => {
    /// Return if list of account ids has not been set
    if (!(props.accounts instanceof Array)) {
      return;
    }

    // Check if maximum number of supported accounts has been selected
    if (props.accounts.length > maxNoAccounts) {
      setIsTooManyAccounts(true);
      setProvider(null);
      setAccountIds(null);
      setDate(null);
      setCustomerId(null);
      setDetails(null);
    } else {
      setIsTooManyAccounts(false);

      const listOfAccountIds = [];
      props.accounts.map((account) => {
        listOfAccountIds.push(account.id);
      });

      const selectedServices = servicesFilterContext
        .getSelectedItems()
        .map((services) => services.id);

      return API.post(`emissions`, `/v1/data/`, {
        body: {
          Period: {
            Start: getDateString(new Date(props.date), false, true),
            End: getDateString(new Date(props.date), true, true)
          },
          Customers: [props.customerId],
          Providers: [props.provider],
          PeriodGranularity: 'Daily',
          Accounts: listOfAccountIds,
          Services: selectedServices,
          GroupBy: ['ACCOUNT', 'RESOURCE', 'SERVICE', 'REGION']
        }
      })
        .then((response) => {
          try {
            response.Data.map((account) => {
              account.Emissions =
                account.CarbonCompute +
                account.CarbonNetwork +
                account.CarbonStorage +
                account.CarbonMemory;
              account.Service = servicesFilterContext
                .getSelectedItems()
                .find((service) => service.id === account.Service).label;
            });
            setAccountIds(listOfAccountIds);
            setDetails(response.Data);
          } catch (error) {
            logger('DetailedEmissionsProvider Responses ERROR', error);
            setProvider(null);
            setAccountIds(null);
            setDate(null);
            setCustomerId(null);
            setDetails(null);
          }
        })
        .catch((error) => {
          logger(`DetailedEmissionsProvider STATUS: ${error.response.status}`);
          logger(`DetailedEmissionsProvider BODY: ${error.response.data.message}`);

          setProvider(null);
          setAccountIds(null);
          setDate(null);
          setCustomerId(null);
          setDetails(null);
        });
    }
  };

  const state = {
    provider,
    accountIds,
    date,
    customerId,
    details,
    isTooManyAccounts,
    maxNoAccounts
  };

  return (
    <DetailedEmissionsContext.Provider value={state}>
      {props.children}
    </DetailedEmissionsContext.Provider>
  );
};

export default DetailedEmissionsContext;
