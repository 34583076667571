import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { TdsSpinner } from '@scania/tegel-react';

function RegionalEmissionsChart(props) {
  const [categories, setCategories] = useState([]);
  const [regionalEmissions, setRegionalEmissions] = useState([]);
  const [regionalEnergy, setRegionalEnergy] = useState([]);
  const options = {
    chart: {
      width: '100%',
      type: 'bar',
      id: 'basic-bar',
      toolbar: {
        show: false
      }
    },
    colors: ['var(--tds-blue-500)', 'var(--tds-orange-500)'],
    legend: {
      onItemClick: {
        toggleDataSeries: false
      }
    },
    xaxis: {
      categories: categories
    },
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      formatter: function (val, o) {
        let unit = o.seriesIndex === 0 ? 'g' : 'Wh';
        return val.toFixed(0) + unit;
      },
      offsetX: 0
    },
    tooltip: {
      y: {
        formatter: (v) => {
          return v.toFixed(0);
        }
      }
    }
  };

  const series = [
    {
      name: 'Emissions',
      data: regionalEmissions
    },
    {
      name: 'Energy',
      data: regionalEnergy
    }
  ];

  useEffect(() => {
    // Get first Region element for each item in props.data
    if (props.data) {
      // Make shallow copy of props.data to avoid mutating the original data
      let data = [...props.data];
      // Sum all the carbon data in each element
      data = data.map((item) => {
        return {
          ...item,
          TotalCarbon:
            item.CarbonCompute +
            item.CarbonMemory +
            item.CarbonStorage +
            item.CarbonNetwork +
            item.CarbonEmbodied
        };
      });
      // sort the data by TotalCarbon in descending order
      data.sort((a, b) => {
        if (b.TotalCarbon > a.TotalCarbon) {
          return 1;
        } else if (b.TotalCarbon < a.TotalCarbon) {
          return -1;
        } else {
          return 0;
        }
      });
      const categories = data.map((item) => {
        return item.Region;
      });
      const emissions = data.map((item) => {
        return item.TotalCarbon;
      });
      const energy = data.map((item) => {
        return item.Energy;
      });

      setCategories([...categories]);
      setRegionalEmissions([...emissions]);
      setRegionalEnergy([...energy]);
    }
  }, [props.data]);

  return (
    <>
      <div className={'tds-container'}>
        {regionalEmissions.length > 0 ? (
          <div className={'position-absolute'} style={{ height: '20rem' }}>
            <Chart height="100%" width="100%" options={options} series={series} type="bar"></Chart>
          </div>
        ) : (
          <div
            className={'position-absolute'}
            style={{ height: '20rem', display: 'grid', placeItems: 'center' }}>
            <TdsSpinner size={'lg'} />
          </div>
        )}
      </div>
    </>
  );
}

export default RegionalEmissionsChart;
