import { TdsHeader, TdsHeaderBrandSymbol, TdsHeaderTitle } from '@scania/tegel-react';
import React from 'react';

function ScaniaHeaderNoAuth() {
  return (
    <div>
      <TdsHeader>
        <TdsHeaderTitle>Cloud Emissions Tracker</TdsHeaderTitle>

        <TdsHeaderBrandSymbol slot="end" key={'brand'}>
          <a href="https://scania.com"></a>
        </TdsHeaderBrandSymbol>
      </TdsHeader>
    </div>
  );
}

export default ScaniaHeaderNoAuth;
