import React, { createContext, useState } from 'react';

export const MenuContext = createContext();

export const MenuProvider = (props) => {
  const [mobileNavExpanded, setMobileNavExpanded] = useState(false);
  const toggleMobileNav = () => setMobileNavExpanded(!mobileNavExpanded);

  const state = {
    mobileNavExpanded: mobileNavExpanded,
    toggleMobileNav: toggleMobileNav
  };

  return <MenuContext.Provider value={state}>{props.children}</MenuContext.Provider>;
};
export default MenuContext;
