import React, { createContext, useState, useContext, useEffect } from 'react';
import { API } from 'aws-amplify';
import CustomersContext from '../Customer/CustomersContext';
import DateFilterContext from '../FilterContexts/DateFilterContext';
import ProvidersFilterContext from '../FilterContexts/ProvidersFilterContext';
import CategoriesFilterContext from '../FilterContexts/CategoriesFilterContext';
import { fillMissingDates, getCarbonForCategories } from '../../utilities/EmissionsCalculations';

export const CompanyTotalsContext = createContext();

export const CompanyTotalsProvider = (props) => {
  const customersContext = useContext(CustomersContext);
  const providersFilterContext = useContext(ProvidersFilterContext);
  const categoriesContext = useContext(CategoriesFilterContext);
  const dateFilterContext = useContext(DateFilterContext);
  const [rawCompanyData, setRawCompanyData] = useState({});
  const [data, setData] = useState({});
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (
      active &&
      customersContext.activeCustomer !== undefined &&
      customersContext.activeCustomer !== null
    ) {
      getCompanyTotalData();
    }
  }, [
    active,
    customersContext.activeCustomer,
    providersFilterContext?.selectedItems,
    dateFilterContext?.startDateUtc,
    dateFilterContext?.endDateUtc
  ]);

  const getCompanyTotalData = () => {
    const provider = providersFilterContext.getSelectedItem()?.id;

    // Set loading state
    setIsLoadingData(true);

    if (provider) {
      API.post(`emissions`, `/v1/data/`, {
        body: {
          Period: {
            Start: dateFilterContext.startDateUtc,
            End: dateFilterContext.endDateUtc
          },
          PeriodGranularity: 'Daily',
          Customers: [customersContext.activeCustomer],
          Providers: [provider],
          Accounts: ['ALL_COMPANY_ACCOUNTS'],
          GroupBy: ['PERIOD']
        }
      })
        .then((resp) => {
          handleApiResponses(resp);
          setRawCompanyData(resp);
        })
        .finally(() => {
          // Set loading state
          setIsLoadingData(false);
        });
    } else {
      setRawCompanyData({});
      setData({});
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    if (Object.keys(rawCompanyData).length > 0) {
      handleApiResponses(rawCompanyData);
    }
  }, [categoriesContext.selectedItems]);

  const handleApiResponses = (response) => {
    if (response) {
      fillMissingDates(response);
      response.Data?.sort((a, b) => new Date(a.Period) - new Date(b.Period));
    }
    let responseData = { Labels: [], Carbon: [], Energy: [] };
    response.Data?.forEach(function (item) {
      responseData.Labels.push(item.Period);
      const categoriesSelected = categoriesContext.getSelectedItems();
      const carbonForUsageType = getCarbonForCategories(item, categoriesSelected);
      responseData.Carbon.push(carbonForUsageType);
      responseData.Energy.push(item.Energy);
    });
    setData(responseData);
  };

  const state = {
    data,
    isLoadingData,
    active,
    setActive
  };

  return (
    <CompanyTotalsContext.Provider value={state}>{props.children}</CompanyTotalsContext.Provider>
  );
};

export default CompanyTotalsContext;
