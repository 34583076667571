import React, { useContext, useEffect, useState } from 'react';
import CustomerUsersContext from '../../../contexts/Auth/CustomerUsersContext';
import UserContext from '../../../contexts/Auth/UserContext';
import UsersTable from '../../../UI/organisms/Settings/UsersTable';

const PermissionsUsersList = ({ setIsActive, isActive }) => {
  const [usersList, setUsersList] = useState(null);
  const customerUsersContext = useContext(CustomerUsersContext);
  const usersContext = useContext(UserContext);

  const getAdminCustomerIds = () => {
    if (!usersContext.adminCustomerIds) {
      return [];
    }

    return usersContext.adminCustomerIds;
  };
  useEffect(() => {
    if (getAdminCustomerIds() && getAdminCustomerIds().length > 0) {
      customerUsersContext.getUsers(usersContext.adminCustomerIds[0]);
    }
  }, [usersContext.adminCustomerIds]);

  useEffect(() => {
    if (customerUsersContext.users) {
      setUsersList(
        customerUsersContext.users?.sort((first, second) =>
          first.Id.toLowerCase() < second.Id.toLowerCase() ? -1 : 1
        )
      );
    }
  }, [customerUsersContext.users]);

  return (
    <>
      {getAdminCustomerIds().length > 0 && (
        <div>
          {usersList && (
            <UsersTable usersList={usersList} isActive={isActive} setIsActive={setIsActive} />
          )}
        </div>
      )}
    </>
  );
};

export default PermissionsUsersList;
