import React from 'react';

const ArrowNarrowDownIcon = (props) => {
  const getHeight = () => {
    return props.height ?? '24';
  };

  const getWidth = () => {
    return props.width ?? '24';
  };

  return (
    <svg
      width={getWidth()}
      height={getHeight()}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 4V20M12 20L18 14M12 20L6 14"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowNarrowDownIcon;
