import React from 'react';

const ReArchitectureIcon = (props) => {
  const getHeight = () => {
    return props.height ?? '24';
  };

  const getWidth = () => {
    return props.width ?? '24';
  };

  return (
    <svg
      width={getWidth()}
      height={getHeight()}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33 12H20.4C15.3595 12 12.8393 12 10.9141 12.9809C9.22063 13.8438 7.8438 15.2206 6.98094 16.914C6 18.8392 6 21.3595 6 26.4V51.6C6 56.6404 6 59.1607 6.98094 61.0859C7.8438 62.7793 9.22063 64.1561 10.9141 65.019C12.8393 66 15.3595 66 20.4 66H45.6C50.6405 66 53.1607 66 55.0859 65.019C56.7794 64.1561 58.1562 62.7793 59.0191 61.0859C60 59.1607 60 56.6404 60 51.6V39M23.9999 48H29.0236C30.4911 48 31.2249 48 31.9154 47.8342C32.5276 47.6872 33.1129 47.4448 33.6497 47.1158C34.2552 46.7447 34.7741 46.2259 35.8118 45.1882L64.5 16.5C66.9853 14.0147 66.9853 9.98523 64.5 7.49995C62.0147 5.01467 57.9853 5.01466 55.5 7.49994L26.8117 36.1882C25.774 37.2259 25.2551 37.7447 24.8841 38.3502C24.5551 38.8871 24.3127 39.4723 24.1657 40.0846C23.9999 40.7751 23.9999 41.5089 23.9999 42.9764V48Z"
        stroke="currentColor"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReArchitectureIcon;
