import React from 'react';

const RelocationIcon = (props) => {
  const getHeight = () => {
    return props.height ?? '24';
  };

  const getWidth = () => {
    return props.width ?? '24';
  };

  return (
    <svg
      width={getWidth()}
      height={getHeight()}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M45 7.37341C42.1589 6.48106 39.1356 6 36 6C19.4315 6 6 19.4315 6 36C6 52.5685 19.4315 66 36 66C52.5685 66 66 52.5685 66 36C66 30.854 64.7043 26.0107 62.4215 21.7783M51 17.25H51.015M31.5002 65.6648L31.5005 59.0548C31.5005 58.6967 31.6286 58.3505 31.8616 58.0787L39.3189 49.3782C39.9318 48.6632 39.7419 47.5669 38.9243 47.0997L30.3556 42.2032C30.1228 42.0702 29.9299 41.8772 29.797 41.6443L24.2114 31.8559C23.9207 31.3465 23.3597 31.0532 22.7755 31.1052L6.19255 32.5822M63 18C63 24.6274 57 30 51 36C45 30 39 24.6274 39 18C39 11.3726 44.3726 6 51 6C57.6274 6 63 11.3726 63 18ZM51.75 17.25C51.75 17.6642 51.4142 18 51 18C50.5858 18 50.25 17.6642 50.25 17.25C50.25 16.8358 50.5858 16.5 51 16.5C51.4142 16.5 51.75 16.8358 51.75 17.25Z"
        stroke="currentColor"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RelocationIcon;
