import React from 'react';
import './Recommendations.css';
import { app_base_path_with_leading_slash } from '../../../config';

function RecommendationsElectricityMap() {
  return (
    <React.Fragment>
      <a href="https://app.electricitymap.org/map" rel="noreferrer" target="_blank">
        <img src={app_base_path_with_leading_slash + '/images/ElectricityMap.png'} />
      </a>
    </React.Fragment>
  );
}

export default RecommendationsElectricityMap;
