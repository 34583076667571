import React, { useEffect, useRef } from 'react';
import logger from '../../../logger';

function HandleOutsideClickAndEsc({ enabled = false, handler = () => {}, children = null }) {
  const refAttribute = useRef(null);

  const isVisibleInViewport = (element) => {
    try {
      var menuBarOffset = 0;
      var y = element.offsetTop;
      var height = element.offsetHeight;

      // Determine the total Y-measurement from browser window
      while ((element = element.offsetParent)) {
        y += element.offsetTop;

        // Assume the top-most parent element under body (0) to be menu bar
        if (element.offsetTop > 0) {
          menuBarOffset = element.offsetTop;
        }
      }

      var yOffset = document.body.getBoundingClientRect().top * -1;
      var maxHeight = y + height - menuBarOffset;
      return y < yOffset + window.innerHeight && maxHeight >= yOffset;
    } catch (error) {
      logger('isVisibleInViewport', error);
      return true;
    }
  };

  useEffect(() => {
    document.addEventListener(
      'keydown',
      (e) => {
        if (enabled === true && e.key === 'Escape') {
          handler();
        }
      },
      true
    );
    document.addEventListener(
      'click',
      (e) => {
        if (enabled === true && refAttribute.current && !refAttribute.current.contains(e.target)) {
          handler();
        }
      },
      true
    );
    document.addEventListener(
      'scroll',
      () => {
        if (enabled === true && !isVisibleInViewport(refAttribute.current)) {
          handler();
        }
      },
      true
    );
  }, []);

  return <span ref={refAttribute}>{children}</span>;
}

// Set default properties
// HandleOutsideClickAndEsc.defaultProps = {
//   children: null,
//   handler: () => {},
//   enabled: false
// };

export default HandleOutsideClickAndEsc;
