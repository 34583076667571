import React from 'react';
import MainHeader from '../../../components/atoms/Typography/MainHeader';
import DivTdsBody01 from '../../../components/atoms/Typography/DivTdsBody01';

function MethodologyLambda() {
  return (
    <React.Fragment>
      <MainHeader>Notes on AWS Lambda</MainHeader>
      <DivTdsBody01>
        AWS Lambda calculations on usage data, which is measured in Gigabyte-seconds (GB-second).
        Lambda pricing is proportional to execution time and allocated memory, and the usage amount
        is a measurement of this.
      </DivTdsBody01>
      <DivTdsBody01>
        Example: A usage of 1 GB-second is equivalent to a 1GB Lambda function with an execution
        time of 1 second, or a 2 GB Lambda function executing for 0.5 seconds.
      </DivTdsBody01>
      <DivTdsBody01>
        According to the{' '}
        <a
          href={
            'https://docs.aws.amazon.com/lambda/latest/dg/configuration-function-common.html#configuration-memory-console'
          }>
          AWS Lambda documentation
        </a>{' '}
        a Lambda function with 1.75GB memory is allocated 1 vCPU and is proportional to the memory
        allocated.
      </DivTdsBody01>
    </React.Fragment>
  );
}

export default MethodologyLambda;
