import React, { useContext, useEffect } from 'react';
import './FilterDropdown.css';
import CheckboxItemList from './CheckboxItemList';
import { TdsButton } from '@scania/tegel-react';

function FilterDropdown({
  itemsContextType = null,
  filterId = undefined,
  controlId = 'DEFAULT',
  handlers = [],
  applyFilterButtonEnabled = true,
  multiSelection = true,
  favouritesEnabled = false,
  sortingEnabled = true,
  additionalButtons = null,
  selectAllEnabled = true,
  selectionSummaryEnabled = false,
  itemTypeLabel = 'ITEMS',
  selectionSummaryCollapsed = false,
  selectionSummaryCollapsable = false,
  additionalItemControls = null,
  emptyContent = 'Reason might be other filter selections or insufficient access rights',
  allCollapsable = false,
  favouritesCollapsed = false,
  favouritesCollapsable = false,
  displayed = false
}) {
  //const [displayed, setDisplayed] = useState(displayed);
  const context = useContext(itemsContextType);

  useEffect(() => {
    // Init context by setting and setting filter at start
    context.setFilterId(filterId);
  }, []);

  useEffect(() => {
    // Config context once filterId has been set
    context.setHandlers(controlId, handlers);

    // Require confirmation if "Apply Filter" button enabled
    context.setSelectionRequireConfirmation(applyFilterButtonEnabled === true);

    // Allow multiselection in context if filter support it
    context.setIsAllowingMultipleSelection(multiSelection === true);

    // Allow favourites in context if filter support it
    context.setIsAllowingFavourites(favouritesEnabled === true);

    // Enable/Disable sorting in context
    context.setIsSortingEnabled(sortingEnabled === true);
  }, [filterId]);

  // Support for context to be re-configured since Account Filter can be both single and multi selection based on provider (TEMP FEATURE UNTIL BACKEND COMPLETE)
  useEffect(() => {
    // Require confirmation if "Apply Filter" button enabled
    context.setSelectionRequireConfirmation(applyFilterButtonEnabled === true);
  }, [applyFilterButtonEnabled]);

  // Support for context to be re-configured since Account Filter can have favourites enabled or disabled depending on the cookie settings
  useEffect(() => {
    // Allow favourites in context if filter support it
    context.setIsAllowingFavourites(favouritesEnabled === true);
  }, [favouritesEnabled]);

  // Support for context to be re-configured since Account Filter can be both single and multi selection based on provider (TEMP FEATURE UNTIL BACKEND COMPLETE)
  useEffect(() => {
    // Allow multiselection in context if filter support it
    context.setIsAllowingMultipleSelection(multiSelection === true);
  }, [multiSelection]);

  const handleApplyFilterClicked = () => {
    context.confirmSelection(controlId);
  };

  const handleItemSelection = (itemId, selected) => {
    context?.setItemSelection(controlId, itemId, selected);
  };

  // Hide dropdown based on displayed
  // useEffect(() => {
  //   setDisplayed(displayed);
  // }, [displayed]);

  const renderButtonsSection = () => {
    return applyFilterButtonEnabled || additionalButtons ? (
      <>
        <div className="filter-dropdown-divider" />
        <div className="filter-dropdown-controls d-flex justify-content-between">
          <span>{renderSelectAllButton()}</span>
          <span>{renderAdditionalButtons()}</span>
          <span>{renderApplyFilterButton()}</span>
        </div>
      </>
    ) : null;
  };

  const renderSelectAllButton = () => {
    return multiSelection && selectAllEnabled ? (
      <TdsButton
        variant="custom"
        onClick={context?.toggleAllItemsSelection}
        size={'sm'}
        text={context?.checkIsAllItemsSelectedIfAny() ? 'Deselect All' : 'Select All'}></TdsButton>
    ) : null;
  };

  const renderApplyFilterButton = () => {
    return applyFilterButtonEnabled ? (
      <TdsButton
        disabled={context.checkIsOnlyOriginalItemsSelected() ? true : false}
        size={'sm'}
        onClick={handleApplyFilterClicked.bind(this)}
        text={'Apply Filter'}></TdsButton>
    ) : null;
  };

  const renderAdditionalButtons = () => {
    return additionalButtons ? <span className="px-1">additionalButtons</span> : null;
  };

  const renderItemsContent = () => {
    return (
      <>
        <CheckboxItemList
          displayed={selectionSummaryEnabled}
          keyPrefix={'select'}
          labelPrefix={'SELECTED'}
          label={itemTypeLabel}
          items={context?.getSelectedItems(false)}
          onSelectionChanged={handleItemSelection}
          justCollapsed={selectionSummaryCollapsed}
          isCollapsable={selectionSummaryCollapsable}
          itemsContextType={itemsContextType}
          additionalItemControls={additionalItemControls}
          favouritesEnabled={favouritesEnabled}
        />
        <CheckboxItemList
          displayed={favouritesEnabled}
          keyPrefix={'favourite'}
          labelPrefix={'FAVOURITE'}
          label={itemTypeLabel}
          items={context?.getFavouriteItems()}
          onSelectionChanged={handleItemSelection}
          collapsed={favouritesCollapsed}
          isCollapsable={favouritesCollapsable}
          itemsContextType={itemsContextType}
          additionalItemControls={additionalItemControls}
          favouritesEnabled={favouritesEnabled}
        />
        <CheckboxItemList
          keyPrefix={'all'}
          labelPrefix={null}
          label={itemTypeLabel}
          items={context?.getItems()}
          onSelectionChanged={handleItemSelection}
          isCollapsable={allCollapsable}
          itemsContextType={itemsContextType}
          additionalItemControls={additionalItemControls}
          favouritesEnabled={favouritesEnabled}
        />
        {renderButtonsSection()}
      </>
    );
  };

  const renderEmptyContent = () => {
    return (
      <>
        <div className="d-flex checkbox-item-list-label">
          NO {itemTypeLabel.toUpperCase()} TO DISPLAY
        </div>
        <div className="d-flex justify-content-center text-center filter-dropdown-empty-content">
          {emptyContent}
        </div>
      </>
    );
  };

  return (
    <div className={displayed ? 'filter-dropdown-base' : 'd-none'}>
      {context.hasItemsOrIsLoading() ? renderItemsContent() : renderEmptyContent()}
    </div>
  );
}

// Set default properties
// FilterDropdown.defaultProps = {
//   // controlId: 'DEFAULT',
//   filterId: undefined,
//   handlers: [],
//   displayed: false,
//   itemsContextType: null,
//   itemTypeLabel: 'ITEMS',
//   multiSelection: true,
//   allCollapsable: false,
//   favouritesEnabled: false,
//   favouritesCollapsed: false,
//   favouritesCollapsable: false,
//   selectAllEnabled: false,
//   applyFilterButtonEnabled: true,
//   selectionSummaryEnabled: false,
//   selectionSummaryCollapsed: false,
//   selectionSummaryCollapsable: false,
//   sortingEnabled: true,
//   additionalButtons: null,
//   additionalItemControls: null,
//   emptyContent: 'Reason might be other filter selections or insufficient access rights'
// };

export default FilterDropdown;
