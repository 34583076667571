function getSum(total, num) {
  if (num) {
    return +total + +num;
  }
  return total || 0.0; // Return 0.0 if the total is calculated on a list of nulls
}

export function getTotal(emissions) {
  let total = 0;

  if (emissions.data.Carbon && emissions.data.Carbon.length > 0) {
    total = emissions.data.Carbon.reduce(getSum).toFixed(2).toString();
  }

  return total;
}
export function getEnergyTotal(emissions) {
  let total = 0;

  if (emissions.data.Energy && emissions.data.Energy.length > 0) {
    total = emissions.data.Energy.reduce(getSum).toFixed(2).toString();
  }
  return total;
}
export function getFilteredPeriod(emissions) {
  if (emissions.data.Labels && emissions.data.Labels.length > 0) {
    return {
      from: emissions.data.Labels[0],
      to: emissions.data.Labels[emissions.data.Labels.length - 1]
    };
  } else {
    return { from: 'unknown', to: 'unknown' };
  }
}
