import React, { useContext, useEffect, useState } from 'react';
import RegionalEmissionsContext from '../../../contexts/Emissions/RegionalEmissionsContext';
import RegionalEmissionsChart from '../../../features/Charts/RegionalEmissions/RegionalEmissionsChart';
import { styled } from 'styled-components';
import CategoriesChart from '../../../features/Charts/CategoryChart/CategoriesChart';
import MainHeader from '../../../components/atoms/Typography/MainHeader';

const Card = styled.div`
  box-sizing: border-box;
  box-shadow: var(--tds-card-box);
  background-color: var(--tds-card-background);
  border-radius: 4px;
  padding: 1rem;
  width: 100%;
  h3 {
    margin-top: 0;
  }
`;

function HomeDashboard() {
  const regionalEmissionsContext = useContext(RegionalEmissionsContext);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (regionalEmissionsContext.data) {
      setData(regionalEmissionsContext.data.Data);
    }
  }, [regionalEmissionsContext.data]);
  return (
    <div className={'tds-container'}>
      <div className={'tds-row'}>
        <div className={'tds-col'}>
          <MainHeader>Welcome back!</MainHeader>
          <p>Your key metrics for the last 30 days:</p>
        </div>
      </div>

      <div className={'tds-row'}>
        <div className={'tds-col-xs-12 tds-col-md-6'}>
          <Card>
            <h3>Emissions by Region (AWS)</h3>
            <RegionalEmissionsChart data={data} />
          </Card>
        </div>
        <div className={'tds-col-xs-12 tds-col-md-6'}>
          <Card>
            <h3>Emissions by Category (AWS)</h3>
            <CategoriesChart data={data} />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default HomeDashboard;
