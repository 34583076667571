import React from 'react';
import HeaderWithSubText from '../../components/atoms/Typography/HeaderWithSubText';
import MobileViewScreen from '../../components/molecules/MobileViewScreen/MobileviewScreen';
import './ApiKeySettings.css';
import { ApiUserContextProvider } from '../../contexts/Auth/ApiUserContext';
// import ApiKeyModal from '../../UI/organisms/ApiKey/ApiKeyModal';
import ApiKeyActions from '../../UI/organisms/ApiKey/ApiKeyActions';

function ApiKeySettings() {
  return (
    <ApiUserContextProvider>
      <div className="tds-container tds-u-mt3 tds-u-mb3 api-key-settings-container">
        <div className="tds-row">
          <div className="tds-col">
            <div>
              <HeaderWithSubText
                text="Manage API Keys"
                subText={'Generate or revoke your API Keys here'}
              />{' '}
            </div>
            <hr className="horizontal-line-tag" />
            <div className="tds-u-mt3">
              <div className="tds-row">
                <div className="tds-col">
                  {/* <ApiKeyModal /> */}
                  <ApiKeyActions />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="api-key-settings-container-small-screen">
        <MobileViewScreen />
      </div>
    </ApiUserContextProvider>
  );
}

export default ApiKeySettings;
