import React, { useContext, useState } from 'react';
import TrendsFilterContext from '../../../contexts/Trends/TrendsFilterContext';
import DateFilter from '../../../components/organisms/Filters/DateFilter/DateFilter';
import ProvidersFilter from '../../../components/organisms/Filters/ProvidersFilter';
import AccountsFilter from '../../../components/organisms/Filters/AccountsFilter/AccountsFilter';
import CategoriesFilter from '../../../components/organisms/Filters/CategoriesFilter';

const TrendsFilter = () => {
  const filterId = 'Trends';
  const trendsFilterContext = useContext(TrendsFilterContext);

  const [baseLineDateSelected, setBaseLineDateSelected] = useState(
    trendsFilterContext.baseLineDate
  );
  const [assessmentDateSelected, setAssessmentDateSelected] = useState(
    trendsFilterContext.assessmentDate
  );

  const handleDateFilterChange = () => {
    trendsFilterContext.setBaseLineDate(baseLineDateSelected);
    trendsFilterContext.setAssessmentDate(assessmentDateSelected);
  };
  return (
    <div className={'tds-u-flex tds-u-flex-wrap tds-u-gap1'}>
      <ProvidersFilter filterId={filterId} closeOnClickOutside={true} />
      <AccountsFilter filterId={filterId} closeOnClickOutside={true} selectAllEnabled={true} />
      <CategoriesFilter filterId={filterId} closeOnClickOutside={true} />
      <DateFilter
        id="baseline"
        showAsDate={false}
        closeOnClickOutside={true}
        selectedDates={baseLineDateSelected}
        setSelectedDates={setBaseLineDateSelected}
        handleDateFilterChange={handleDateFilterChange}
      />
      <DateFilter
        id="assessment"
        showAsDate={false}
        closeOnClickOutside={true}
        selectedDates={assessmentDateSelected}
        setSelectedDates={setAssessmentDateSelected}
        handleDateFilterChange={handleDateFilterChange}
      />
    </div>
  );
};

export default TrendsFilter;
