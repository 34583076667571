import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AuthContext from '../../../contexts/common/AuthContext';
import { app_base_path_with_leading_slash } from '../../../config';

const PrivateRoute = () => {
  const authContext = useContext(AuthContext);

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return authContext.isLoggedIn() ? (
    <Outlet />
  ) : (
    <Navigate to={app_base_path_with_leading_slash + '/login'} />
  );
};

export default PrivateRoute;
