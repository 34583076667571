import React, { useContext, useEffect, useState } from 'react';
import ApiUserContext from '../../../contexts/Auth/ApiUserContext';
import { TdsButton, TdsModal, TdsTextarea } from '@scania/tegel-react';

function ApiKeyModal() {
  const [showApiKey, setShowApiKey] = useState(false);
  const [showCopyButtonTooltip, setShowCopyButtonTooltip] = useState(false);

  const apiUserContext = useContext(ApiUserContext);

  useEffect(() => {
    // Set timeout to hide Copy Button Tooltip
    if (showCopyButtonTooltip === true) {
      setTimeout(() => {
        setShowCopyButtonTooltip(false);
      }, 5000);
    }
  }, [showCopyButtonTooltip]);
  // const handleClose = () => {
  //   resetApiKey();
  // };

  const copyOnClick = () => {
    navigator.clipboard.writeText(getApiKey());
    setShowCopyButtonTooltip(true);
  };
  const apiKeyShowHideOnClick = () => {
    setShowApiKey((prevState) => !prevState);
  };

  const getApiKeyAsMaskedString = () => {
    let maskedString = '';
    Array.from(getApiKey()).forEach(() => {
      maskedString += '•';
    });
    return maskedString;
  };

  // const copyButtonTooltip = (props) => (
  //   <Tooltip id="copy-cutton-tooltip" {...props}>
  //     Copied to your clipboard!
  //   </Tooltip>
  // );

  const getApiKey = () => {
    if (apiUserContext.apiKeyOne) {
      return apiUserContext.apiKeyOne;
    } else if (apiUserContext.apiKeyTwo) {
      return apiUserContext.apiKeyTwo;
    }
    return '';
  };
  const resetApiKey = () => {
    if (apiUserContext.apiKeyOne) {
      apiUserContext.resetKeyOne();
    } else if (apiUserContext.apiKeyTwo) {
      apiUserContext.resetKeyTwo();
    }
  };
  return (
    <>
      <TdsModal
        show={true}
        prevent={true}
        actions-position="static"
        size="lg"
        // selector={selector}
        onTdsClose={resetApiKey}>
        {' '}
        <h5 className="tds-modal-headline" slot="header">
          Copy the API Key below
        </h5>
        <span slot="body">
          <div className="tds-u-pt3">
            Although you can always make more API keys, be sure to copy this key now as you
            won&apos;t be able to retrieve it again.
          </div>
          <div className="tds-u-pt3">
            <TdsTextarea
              rows="3"
              label="Label"
              value={showApiKey ? getApiKey() : getApiKeyAsMaskedString()}
              readOnly={true}
              disabled></TdsTextarea>
            <span>
              <TdsButton size="md" text="Copy Key" onClick={copyOnClick.bind(this)}></TdsButton>
              <TdsButton
                size="md"
                variant="secondary"
                className="tds-u-ml1"
                text={showApiKey ? 'Hide API Key' : 'Show API Key'}
                onClick={apiKeyShowHideOnClick.bind(this)}></TdsButton>
            </span>
          </div>
        </span>
        <div>
          <div>
            <b>IMPORTANT: Keep this API key secure!</b>
          </div>
          <div>
            Anyone who has it can perform actions against our API endpoints and impersonate you.
          </div>
        </div>
      </TdsModal>

      {/* <Modal
        show={checkIfApiKeyExists()}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="lg">
        <Modal.Body className="o-apikey-apikeymodal-body">
          <div className="d-flex align-items-center">
            <div className="flex-grow-1 o-apikey-apikeymodal-heading-text">
              Copy the API Key below
            </div>
            <Button
              variant="unstyled"
              title="Close"
              className="o-apikey-apikeymodal-heading-close-button"
              onClick={handleClose.bind(this)}>
              <XIcon />
            </Button>
          </div>
          <div className="pt-3">
            Although you can always make more API keys, be sure to copy this key now as you
            won&apos;t be able to retrieve it again.
          </div>
          <div className="pt-3">
            <Form.Control
              as="textarea"
              rows={3}
              value={showApiKey ? getApiKey() : getApiKeyAsMaskedString()}
              className="o-apikey-apikeymodal-key-input"
              readOnly={true}
            />
          </div>
          <div className="d-flex pt-3 gap-2">
            <OverlayTrigger
              show={showCopyButtonTooltip}
              placement="top"
              delay={{ show: 250, hide: 750 }}
              overlay={copyButtonTooltip}>
              <Button
                className="rounded o-apikey-apikeymodal-copy-button"
                onClick={copyOnClick.bind(this)}>
                <Copy07Icon />
                <span className="ps-2">Copy key</span>
              </Button>
            </OverlayTrigger>
            <Button
              className="rounded o-apikey-apikeymodal-copy-button"
              onClick={apiKeyShowHideOnClick.bind(this)}>
              {showApiKey ? (
                <>
                  <EyeOffIcon />
                  <span className="ps-2">Hide API Key</span>
                </>
              ) : (
                <>
                  <EyeIcon />
                  <span className="ps-2">Show API Key</span>
                </>
              )}
            </Button>
          </div>
          <div className="d-flex align-items-center pt-4 o-apikey-apikeymodal-warning">
            <span className="px-3">
              <AnnotationAlertIcon color="var(--gaia-color-warning-500)" width={48} height={48} />
            </span>
            <span>
              <div>
                <b>IMPORTANT: Keep this API key secure!</b>
              </div>
              <div>
                Anyone who has it can perform actions against our API endpoints and impersonate you.
              </div>
            </span>
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
}

export default ApiKeyModal;
