import React from 'react';

const ChevronRightIcon = (props) => {
  const getHeight = () => {
    return props.height ?? '24';
  };

  const getWidth = () => {
    return props.width ?? '24';
  };

  return (
    <svg
      height={getHeight()}
      width={getWidth()}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 18L15 12L9 6"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChevronRightIcon;
