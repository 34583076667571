import React, { createContext, useState } from 'react';
import uuid from 'uuid';

export const AlertContext = createContext();

export const AlertProvider = (props) => {
  const [alerts, setAlerts] = useState([]);

  const addAlert = (message, isError, onClose) => {
    setAlerts((prevAlerts) => {
      return [
        ...prevAlerts.slice(-5),
        { message: message, error: isError, onClose: onClose, show: true, key: uuid() }
      ];
    });
  };

  const state = {
    alerts: alerts,
    addAlert: addAlert
  };

  return <AlertContext.Provider value={state}>{props.children}</AlertContext.Provider>;
};
export default AlertContext;
