import React from 'react';

import './LoadingContent.css';

function LoadingContent(props) {
  const getFlashing = () => {
    return props.flashing === undefined || props.flashing.toString().toLowerCase() === 'true';
  };

  const getTheme = () => {
    if (props.theme !== undefined && props.theme === 'white') {
      return 'white';
    }
    return 'turquoise';
  };

  const applyThemeToClassName = (classes) => {
    return classes + ' loading-content-theme-' + getTheme();
  };

  const getLoadingHeading = () => {
    if (props.loadingHeading !== undefined) {
      return String(props.loadingHeading);
    }
    return 'CONTENT IS BEING LOADED';
  };

  const getLoadingMessage = () => {
    if (props.loadingMessage !== undefined) {
      return String(props.loadingMessage);
    }
    return 'PLEASE HOLD';
  };

  return (
    <div className={applyThemeToClassName('loading-content-container')}>
      <div className={getFlashing() ? 'loading-content-animation-transition' : ''}>
        <div style={{ textAlign: 'center' }}>
          <span className="visually-hidden">Loading...</span>
        </div>
        <div
          className={applyThemeToClassName(
            'loading-content-text-heading loading-content-text-heading-size loading-content-spacing-heading'
          )}>
          {getLoadingHeading()}
        </div>
        <div className={applyThemeToClassName('loading-content-divider')} />
        <div
          className={applyThemeToClassName(
            'loading-content-text-bread loading-content-text-bread-size loading-content-spacing-bread'
          )}>
          {getLoadingMessage()}
        </div>
      </div>
    </div>
  );
}

export default LoadingContent;
