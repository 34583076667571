import { createContext } from 'react';
import FilterContextBase from './FilterContextBase';

export const ServicesFilterContext = createContext();

export class ServicesFilterContextProvider extends FilterContextBase {
  // Constructor
  constructor(props) {
    super(props, ServicesFilterContext, Object.keys({ ServicesFilterContext })[0]);

    // Define default state (extended from parent) - Using "incorrect" way of setting state (not using "setState") is OK since it's being defined in constructor
    this.state = {
      ...this.state,
      ...{ isFetchingItems: true, fetchItems: this.fetchItems, provider: undefined }
    };
  }

  fetchItems = (provider) => {
    this.setState({ isFetchingItems: true });

    if (provider === undefined) {
      provider = this.state.provider;
    } else {
      this.setState({ provider: provider });
    }

    this.setItems(this.getServicesByProvider(provider));

    this.setState({ isFetchingItems: false });
  };
  getServicesByProvider = (provider) => {
    switch (provider) {
      case 'AWS':
        return [
          // Potential missing services from backend...? awswaf, AWSCertificateManager, AWSSecretsManager, AmazonECR
          { id: 'ApiGateway', backendId: 'AmazonApiGateway', label: 'API Gateway', selected: true },
          { id: 'Backup', backendId: 'AWSBackup', label: 'Backup', selected: true },
          { id: 'CloudFront', backendId: 'AmazonCloudFront', label: 'CloudFront', selected: true },
          { id: 'CloudWatch', backendId: 'AmazonCloudWatch', label: 'CloudWatch', selected: true },
          { id: 'DynamoDB', backendId: 'AmazonDynamoDB', label: 'DynamoDB', selected: true },
          {
            id: 'EC2',
            backendId: 'AmazonEC2',
            label: 'EC2',
            subLabel: 'Elastic Compute Cloud',
            selected: true
          },
          {
            id: 'ECS',
            backendId: 'AmazonECS',
            label: 'ECS',
            subLabel: 'Elastic Container Service',
            selected: true
          },
          {
            id: 'EFS',
            backendId: 'AmazonEFS',
            label: 'EFS',
            subLabel: 'Elastic File System',
            selected: true
          },
          {
            id: 'EKS',
            backendId: 'AmazonEKS',
            label: 'EKS',
            subLabel: 'Elastic Kubernetes Service',
            selected: true
          },
          {
            id: 'ELB',
            backendId: 'AWSELB',
            label: 'ELB',
            subLabel: 'Elastic Load Balancing',
            selected: true
          },
          {
            id: 'ElastiCache',
            backendId: 'AmazonElastiCache',
            label: 'ElastiCache',
            selected: true
          },
          { id: 'Lambda', backendId: 'AWSLambda', label: 'Lambda', selected: true },
          {
            id: 'MSK',
            backendId: 'AmazonMSK',
            label: 'MSK',
            subLabel: 'Managed Streaming for Apache Kafka',
            selected: true
          },
          {
            id: 'RDS',
            backendId: 'AmazonRDS',
            label: 'RDS',
            subLabel: 'Relational Database Service',
            selected: true
          },
          { id: 'Route53', backendId: 'AmazonRoute53', label: 'Route 53', selected: true },
          {
            id: 'S3',
            backendId: 'AmazonS3',
            label: 'S3',
            subLabel: 'Simple Storage Service',
            selected: true
          },
          {
            id: 'SNS',
            backendId: 'AmazonSNS',
            label: 'SNS',
            subLabel: 'Simple Notification Service',
            selected: true
          },
          {
            id: 'SQS',
            backendId: 'AWSQueueService',
            label: 'SQS',
            subLabel: 'Simple Queue Service',
            selected: true
          },
          { id: 'SimpleDB', backendId: 'AmazonSimpleDB', label: 'SimpleDB', selected: true },
          {
            id: 'SystemsManager',
            backendId: 'AWSSystemsManager',
            label: 'Systems Manager',
            selected: true
          },
          {
            id: 'VPC',
            backendId: 'AmazonVPC',
            label: 'VPC',
            subLabel: 'Virtual Private Cloud',
            selected: true
          }
        ];
      case 'AZURE':
        return [
          {
            id: 'APIManagement',
            backendId: 'API Management',
            label: 'API Management',
            selected: true
          },
          {
            id: 'AzureAppService',
            backendId: 'Azure App Service',
            label: 'App Service',
            selected: true
          },
          {
            id: 'AzureSQLDatabase',
            backendId: 'SQL Database',
            label: 'SQL Database',
            selected: true
          },
          {
            id: 'AzureSQLManagedInstances',
            backendId: 'SQL Managed Instance',
            label: 'SQL Managed Instance',
            selected: true
          },
          {
            id: 'ContainerInstances',
            backendId: 'Container Instances',
            label: 'Container Instances',
            selected: true
          },
          {
            id: 'ContainerRegistry',
            backendId: 'Container Registry',
            label: 'Container Registry',
            selected: true
          },
          { id: 'CosmosDB', backendId: 'Azure Cosmos DB', label: 'Cosmos DB', selected: true },
          { id: 'Functions', backendId: 'Functions', label: 'Functions', selected: true },
          {
            id: 'KubernetesService',
            backendId: 'Azure Kubernetes Service',
            label: 'AKS',
            subLabel: 'Azure Kubernetes Service',
            selected: true
          },
          {
            id: 'MySQLDatabase',
            backendId: 'Azure Database for MySQL',
            label: 'Database for MySQL',
            selected: true
          },
          {
            id: 'PostgreSQLDatabase',
            backendId: 'Azure Database for PostgreSQL',
            label: 'Database for PostgreSQL',
            selected: true
          },
          { id: 'Storage', backendId: 'Storage', label: 'Storage', selected: true },
          {
            id: 'VirtualMachines',
            backendId: 'Virtual Machines',
            label: 'Virtual Machines',
            selected: true
          }
        ];

      default:
        return [];
    }
  };
}

// Set default properties
ServicesFilterContextProvider.defaultProps = {
  children: null
};

export default ServicesFilterContext;
