import React, { createContext, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import config, { app_base_path_with_leading_slash, app_path_prefix } from './../../config';

export const AuthContext = createContext();

export const AuthProvider = (props) => {
  const [initials, setInitials] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);

  useEffect(() => {
    const getPathnameWithoutPrefix = (prefix) => {
      let pathname = window.location.pathname;
      return pathname.startsWith(`/${prefix}/`)
        ? pathname.replace('/', '').replace(`${prefix}/`, '')
        : pathname.replace('/', '');
    };

    Auth.currentSession()
      .then((user) => {
        // Set name from session
        const user_payload = user.getIdToken().decodePayload();

        setInitials(
          user_payload.given_name.substring(0, 1) + user_payload.family_name.substring(0, 1)
        );
        setFirstName(user_payload.given_name);
        setLastName(user_payload.family_name);
        setEmail(user_payload.email);

        if (getPathnameWithoutPrefix(app_base_path_with_leading_slash).toLowerCase() === 'login') {
          window.location.href = app_base_path_with_leading_slash + '/';
        }
      })
      .catch(() => {
        // Triggers when no logged-in user

        // Set auth context for DEMO
        if (config.ENVIRONMENT === 'DEMO') {
          setInitials('GD');
          setFirstName('CET');
          setLastName('DEMO');
          setEmail('CET DEMO');
        } else {
          // Set blank auth context
          setInitials(null);

          if (getPathnameWithoutPrefix(app_path_prefix).toLowerCase() !== 'login') {
            window.location.href = app_base_path_with_leading_slash + '/login';
          }
        }
      });
  }, [initials]);

  const isLoggedIn = () => initials !== null; // TODO: Is there a cleaner way to do this?

  const logOut = () => {
    Auth.signOut()
      .then(() => {
        console.log('Logged out');
      })
      .catch((e) => {
        console.log(e);
      });
    setInitials(null);
  };

  const state = {
    initials: initials,
    firstName: firstName,
    lastName: lastName,
    email: email,
    isLoggedIn: isLoggedIn,
    logOut: logOut
  };

  return <AuthContext.Provider value={state}>{props.children}</AuthContext.Provider>;
};
export default AuthContext;
