import React, { createContext, useState } from 'react';
import { subDays, format } from 'date-fns';
import { getDateString } from '../../utilities/Datestring';

export const DateFilterContext = createContext();

export const DateFilterContextProvider = (props) => {
  const [dateRange, setDateRange] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: subDays(new Date(), 1),
      key: 'selection'
    }
  ]);

  const [startDateUtc, setStartDateUtc] = useState(
    getDateString(dateRange[0].startDate, false, true)
  );
  const [endDateUtc, setEndDateUtc] = useState(getDateString(dateRange[0].endDate, 'end', true));
  const onDateFilterChange = (selectedDateRange) => {
    if (
      format(selectedDateRange[0].startDate, 'dd-MM-yyyy') !==
        format(dateRange[0].startDate, 'dd-MM-yyyy') ||
      format(selectedDateRange[0].endDate, 'dd-MM-yyyy') !==
        format(dateRange[0].endDate, 'dd-MM-yyyy')
    ) {
      setDateRange(selectedDateRange);
      setStartDateUtc(getDateString(selectedDateRange[0].startDate, false, true));
      setEndDateUtc(getDateString(selectedDateRange[0].endDate, 'end', true));
    }
  };

  const state = {
    dateRange,
    setDateRange,
    onDateFilterChange,
    startDateUtc,
    setStartDateUtc,
    endDateUtc,
    setEndDateUtc
  };

  return <DateFilterContext.Provider value={state}>{props.children}</DateFilterContext.Provider>;
};
export default DateFilterContext;
