import React, { createContext, useState } from 'react';
import { API } from 'aws-amplify';
import config from './../../config';

export const SSOContext = createContext();

export const SSOProvider = (props) => {
  const [redirectURL, setRedirectURL] = useState(undefined);

  function fetchSSORedirectURL(email) {
    setRedirectURL(undefined);
    API.get(`idp`, `/v1/idp/id?email=${email}`)
      .then((response) => {
        setRedirectURL(config.LOGIN_URL + `&identity_provider=${response.IdentityProvider}`);
      })
      .catch(() => {
        setRedirectURL(null);
      });
  }

  const state = {
    fetchSSORedirectURL: fetchSSORedirectURL,
    redirectURL: redirectURL
  };

  return <SSOContext.Provider value={state}>{props.children}</SSOContext.Provider>;
};

export default SSOContext;
