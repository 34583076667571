import { createContext } from 'react';
import FilterContextBase from './FilterContextBase';
import CustomerAccountsContext from '../Customer/CustomerAccountsContext';

export const AccountsFilterContext = createContext();

export class AccountsFilterContextProvider extends FilterContextBase {
  static contextType = CustomerAccountsContext;

  // Constructor
  constructor(props) {
    super(props, AccountsFilterContext, Object.keys({ AccountsFilterContext })[0]);

    // Define default state (extended from parent) - Using "incorrect" way of setting state (not using "setState") is OK since it's being defined in constructor
    this.state = {
      ...this.state,
      ...{ isFetchingItems: true, fetchItems: this.fetchItems, provider: undefined }
    };
  }

  fetchItems = (provider) => {
    this.setState({ isFetchingItems: true });

    // Try and read provider from state if it's undefined (not specified)
    if (provider === undefined) {
      provider = this.state.provider;
    } else {
      this.setState({ provider: provider });
    }

    if (this.context?.accounts instanceof Array) {
      let accounts = this.context.accounts
        .filter((account) => account.Provider === provider)
        .map((account) => {
          return {
            id: account.AccountId,
            label: account.Alias,
            subLabel: account.AccountId,
            canEdit: account.CanEdit,
            blur: localStorage.getItem('blur')?.toLowerCase() === 'true'
          };
        });
      this.setItems(accounts);

      this.setState({ isFetchingItems: false });
    }
  };
}

// Set default properties
AccountsFilterContextProvider.defaultProps = {
  children: null
};

export default AccountsFilterContext;
