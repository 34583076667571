import { API } from 'aws-amplify';
import React, { useState, createContext, useContext } from 'react';
import CustomersContext from '../Customer/CustomersContext';
import AlertContext from '../common/AlertContext';
import logger from '../../logger';

export const AggregatedContext = createContext();

const AggregatedProvider = (props) => {
  const [aggregatedData, setAggregatedData] = useState(null);
  const [isApiCall, setIsApiCall] = useState(false);
  const [error, setError] = useState(false);
  const customerContext = useContext(CustomersContext);
  const alertContext = useContext(AlertContext);
  const getAggregatedData = (
    provider,
    accountIds,
    startDateUtcBaseline,
    endDateUtcBaseline,
    startDateUtcAssessment,
    endDateUtcAssessment
  ) => {
    setIsApiCall(true);
    API.post(`emissions`, `/v1/compare`, {
      body: {
        Accounts: accountIds,
        Customers: customerContext.customers,
        Providers: [provider],
        BaselinePeriod: {
          Start: startDateUtcBaseline,
          End: endDateUtcBaseline
        },
        AssessmentPeriod: {
          Start: startDateUtcAssessment,
          End: endDateUtcAssessment
        }
      }
    })
      .then((resp) => {
        setAggregatedData(resp);
      })
      .catch(() => {
        logger(error);
        alertContext.addAlert('An error occurred!', true, () => setError(false));
      })
      .finally(() => {
        setIsApiCall(false);
      });
  };
  const state = {
    aggregatedData,
    isApiCall,
    getAggregatedData
  };

  return <AggregatedContext.Provider value={state}>{props.children}</AggregatedContext.Provider>;
};
export default AggregatedProvider;
