import {
  TdsHeader,
  TdsHeaderBrandSymbol,
  TdsHeaderDropdown,
  TdsHeaderDropdownList,
  TdsHeaderDropdownListItem,
  TdsHeaderDropdownListUser,
  TdsHeaderHamburger,
  TdsHeaderItem,
  TdsHeaderTitle,
  TdsIcon
} from '@scania/tegel-react';
import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AuthContext from '../../contexts/common/AuthContext';
import MenuContext from '../../contexts/MenuContext/MenuContext';
import config, { app_base_path_with_leading_slash } from '../../config';
import { styled } from 'styled-components';

const HeaderWrapper = styled.div`
  position: fixed;
  width: 100%;
  z-index: 200;
`;

function ScaniaHeaderAuth() {
  const auth = useContext(AuthContext);
  const menuContext = useContext(MenuContext);
  const { pathname } = useLocation();

  return (
    <HeaderWrapper>
      <TdsHeader>
        <TdsHeaderHamburger
          onClick={menuContext.toggleMobileNav}
          aria-label="Open application drawer"
          aria-haspopup="true"
          aria-expanded="false"></TdsHeaderHamburger>

        <TdsHeaderTitle>Cloud Emissions Tracker</TdsHeaderTitle>
        <TdsHeaderItem selected={pathname === app_base_path_with_leading_slash + '/home'}>
          <Link to={app_base_path_with_leading_slash + '/home'}>Home</Link>
        </TdsHeaderItem>
        <TdsHeaderDropdown>
          <span slot="label">Emissions</span>
          <TdsHeaderDropdownList>
            <TdsHeaderDropdownListItem
              selected={pathname === app_base_path_with_leading_slash + '/emissions'}>
              <Link to={app_base_path_with_leading_slash + '/emissions'}>Details</Link>
            </TdsHeaderDropdownListItem>
            <TdsHeaderDropdownListItem
              selected={pathname === app_base_path_with_leading_slash + '/compare'}>
              <Link to={app_base_path_with_leading_slash + '/compare'}>Compare</Link>
            </TdsHeaderDropdownListItem>
          </TdsHeaderDropdownList>
        </TdsHeaderDropdown>

        <TdsHeaderDropdown>
          <span slot="label">Documentation</span>
          <TdsHeaderDropdownList>
            <TdsHeaderDropdownListItem
              selected={pathname === app_base_path_with_leading_slash + '/about'}>
              <Link to={app_base_path_with_leading_slash + '/about'}>About</Link>
            </TdsHeaderDropdownListItem>

            <TdsHeaderDropdownListItem
              selected={pathname === app_base_path_with_leading_slash + '/glossary'}>
              <Link to={app_base_path_with_leading_slash + '/glossary'}>Glossary</Link>
            </TdsHeaderDropdownListItem>

            <TdsHeaderDropdownListItem
              selected={pathname === app_base_path_with_leading_slash + '/methodology'}>
              <Link to={app_base_path_with_leading_slash + '/methodology'}>Methodology</Link>
            </TdsHeaderDropdownListItem>

            <TdsHeaderDropdownListItem
              selected={pathname === app_base_path_with_leading_slash + '/recommendations'}>
              <Link to={app_base_path_with_leading_slash + '/recommendations'}>
                Recommendations
              </Link>
            </TdsHeaderDropdownListItem>

            <TdsHeaderDropdownListItem
              selected={pathname === app_base_path_with_leading_slash + '/developers'}>
              <Link to={app_base_path_with_leading_slash + '/developers'}>Developers</Link>
            </TdsHeaderDropdownListItem>
            <TdsHeaderDropdownListItem
              selected={pathname === app_base_path_with_leading_slash + '/integration'}>
              <Link to={app_base_path_with_leading_slash + '/integration'}>Integration</Link>
            </TdsHeaderDropdownListItem>
          </TdsHeaderDropdownList>
        </TdsHeaderDropdown>

        <span className="menu-navbar-item-spacer" />

        <TdsHeaderDropdown onClick={() => {}} slot="end" no-dropdown-icon>
          <div slot="icon">
            <TdsIcon alt={'User menu'} name="profile" size="24"></TdsIcon>
          </div>
          <TdsHeaderDropdownList size="lg">
            <TdsHeaderDropdownListUser
              header={auth?.firstName + ' ' + auth?.lastName}></TdsHeaderDropdownListUser>
            <TdsHeaderDropdownListItem>
              <Link target="_blank" to={config.CLASSIC_APP_SITE_URL}>
                <TdsIcon name="redirect"></TdsIcon>
                <div className="tds-u-pl1">Go to original site</div>
              </Link>
            </TdsHeaderDropdownListItem>
            <TdsHeaderDropdownListItem>
              <Link to={app_base_path_with_leading_slash + '/settings'}>
                <TdsIcon name="settings"></TdsIcon>
                <div className="tds-u-pl1">Settings</div>
              </Link>
            </TdsHeaderDropdownListItem>
            <TdsHeaderDropdownListItem>
              <a href="#" onClick={auth.logOut}>
                <div>Sign out</div>
              </a>
            </TdsHeaderDropdownListItem>
          </TdsHeaderDropdownList>
        </TdsHeaderDropdown>

        <TdsHeaderBrandSymbol slot="end" key={'brand'}>
          <a href="https://scania.com"></a>
        </TdsHeaderBrandSymbol>
      </TdsHeader>
    </HeaderWrapper>
  );
}

export default ScaniaHeaderAuth;
