import React, { useContext } from 'react';
import CustomerAccountsContext from '../../contexts/Customer/CustomerAccountsContext';
import UserContext from '../../contexts/Auth/UserContext';
import CustomersContext from '../../contexts/Customer/CustomersContext';
import MobileViewScreen from '../../components/molecules/MobileViewScreen/MobileviewScreen';
import useLocalStorage from 'use-local-storage';
import HeaderWithSubText from '../../components/atoms/Typography/HeaderWithSubText';
import Accordion from '../../UI/organisms/Accordion/Accordion';
import { TdsAccordionItem, TdsCheckbox } from '@scania/tegel-react';
import './MyPermissions.css';

const MyPermissions = () => {
  const customerAccountsContext = useContext(CustomerAccountsContext);
  const userContext = useContext(UserContext);
  const customerContext = useContext(CustomersContext);
  const [blurSensitive] = useLocalStorage('blur', false);

  const blurIfEnabled = (classes) => (blurSensitive ? classes + ' blur' : classes);

  const getAdminCustomerIds = () => {
    // Protect against initialization and null-values by returning empty array
    if (!userContext.adminCustomerIds) {
      return [];
    }

    return userContext.adminCustomerIds;
  };

  const getCustomerAccounts = () => {
    // Protect against initialization and null-values by returning empty array
    if (!customerAccountsContext.accounts) {
      return [];
    }

    return customerAccountsContext.accounts;
  };
  const getUserGroups = () => {
    // Protect against initialization and null-values by returning empty array
    if (!userContext.groupIds) {
      return [];
    }

    return userContext.groupIds;
  };

  return (
    <>
      <div className="tds-container tds-u-mt3 tds-u-mb3 my-permissions-container">
        <div className="tds-row">
          <div className="tds-col">
            <HeaderWithSubText text="My Permissions" subText="Summary of your permissions" />{' '}
          </div>
        </div>
        <hr className="horizontal-line-tag" />
        <div className="tds-u-mt3">
          <div className="tds-row">
            <div className="tds-col">
              <div className="tds-headline-06">Email</div>
              <p>{userContext.email}</p>
            </div>
          </div>
          <div className="tds-row">
            <div className="tds-col">
              <div className="tds-headline-06">Organization role</div>
              <p>
                {getAdminCustomerIds().includes(customerContext.activeCustomer) ? 'Admin' : 'User'}
              </p>
            </div>
          </div>
          <div className="tds-row">
            <div className="tds-col">
              <Accordion>
                {' '}
                <TdsAccordionItem header="The accounts that you have permissions to view">
                  {getCustomerAccounts().map((account, i) => (
                    <div className="tds-u-mb1" key={i}>
                      <TdsCheckbox checked id="custom-switch" disabled="disabled">
                        <div slot="label" className={blurIfEnabled()}>
                          {account.Alias
                            ? `${account.Alias} (${account.AccountId})`
                            : account.AccountId}
                        </div>
                      </TdsCheckbox>
                    </div>
                  ))}
                </TdsAccordionItem>{' '}
                <TdsAccordionItem header="The accounts that you have permissions to edit alias for">
                  {getCustomerAccounts().map((account, i) => {
                    return (
                      account.CanEdit && (
                        <div className="tds-u-mb1" key={i}>
                          <TdsCheckbox checked id="custom-switch" disabled="disabled">
                            {' '}
                            <div slot="label" className={blurIfEnabled()}>
                              {account.Alias
                                ? `${account.Alias} (${account.AccountId})`
                                : account.AccountId}
                            </div>
                          </TdsCheckbox>
                        </div>
                      )
                    );
                  })}
                </TdsAccordionItem>{' '}
                <TdsAccordionItem header="This a list of groups you belong to">
                  {getUserGroups().map((group, i) => {
                    return (
                      <div key={i}>
                        <TdsCheckbox checked id="custom-switch" disabled="disabled">
                          {' '}
                          <div slot="label" className={blurIfEnabled()}>
                            {group}
                          </div>
                        </TdsCheckbox>
                      </div>
                    );
                  })}
                </TdsAccordionItem>{' '}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <div className="my-permissions-container-small-screen">
        <MobileViewScreen />
      </div>
    </>
  );
};

export default MyPermissions;
