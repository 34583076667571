import React from 'react';
import 'katex/dist/katex.min.css';
import './Methodology.css';
import { TdsNavigationTab, TdsNavigationTabs } from '@scania/tegel-react';
import { Link, Route, Routes } from 'react-router-dom';
import MethodologyAbout from './Components/MethodologyAbout';
import MethodologyCompute from './Components/MethodologyCompute';
import MethodologyEC2 from './Components/MethodologyEC2';
import MethodologyLambda from './Components/MethodologyLambda';
import MethodologyScience from './Components/MethodologyScience';
import MethodologyReferences from './Components/MethodologyReferences';

function MethodologyScene() {
  return (
    <div>
      <TdsNavigationTabs defaultSelectedIndex={0}>
        <TdsNavigationTab>
          <Link to={''}>About</Link>
        </TdsNavigationTab>
        <TdsNavigationTab>
          <Link to={'science'}>Science</Link>
        </TdsNavigationTab>
        <TdsNavigationTab>
          <Link to={'compute'}>Compute</Link>
        </TdsNavigationTab>
        <TdsNavigationTab>
          <Link to={'ec2'}>AWS EC2</Link>
        </TdsNavigationTab>
        <TdsNavigationTab>
          <Link to={'lambda'}>AWS Lambda</Link>
        </TdsNavigationTab>
        <TdsNavigationTab>
          <Link to={'references'}>References</Link>
        </TdsNavigationTab>
      </TdsNavigationTabs>

      <div className={'tds-container'}>
        <div className={'tds-row'}>
          <div className={'tds-col'}>
            <Routes>
              <Route index element={<MethodologyAbout />} />
              <Route path={'science'} element={<MethodologyScience />} />
              <Route path={'compute'} element={<MethodologyCompute />} />
              <Route path={'ec2'} element={<MethodologyEC2 />} />
              <Route path={'lambda'} element={<MethodologyLambda />} />
              <Route path={'references'} element={<MethodologyReferences />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MethodologyScene;
