import React, { useContext, useEffect, useState } from 'react';
import CustomerAccountsContext from '../../../contexts/Customer/CustomerAccountsContext';
import CustomersContext from '../../../contexts/Customer/CustomersContext';
import UserPermissionsContext from '../../../contexts/Permissions/UserPermissionsContext';
import useLocalStorage from 'use-local-storage';
import { TdsButton, TdsCheckbox } from '@scania/tegel-react';

const PermissionsActionsForm = ({ propKey, list }) => {
  const customerAccountsContext = useContext(CustomerAccountsContext);
  const userPermissionsContext = useContext(UserPermissionsContext);
  const customersContext = useContext(CustomersContext);
  const [cancel, setCancel] = useState(false);
  const [allAccounts, setAllAccounts] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [checkedSelectAll, setCheckedSelectAll] = useState(false);
  const [blurSensitive] = useLocalStorage('blur', false);
  const blurIfEnabled = (classes) => (blurSensitive ? classes + ' blur' : classes);

  useEffect(() => {
    if (list && propKey === 'ViewAccounts' && customerAccountsContext.accountsCustomer) {
      JSON.stringify(list) ===
      JSON.stringify(customerAccountsContext.accountsCustomer.map((item) => item.AccountId))
        ? setCheckedSelectAll(true)
        : setCheckedSelectAll(false);

      const array = customerAccountsContext.accountsCustomer.map((item) => {
        const checked = !!(list && list.includes(item.AccountId));
        return { [item.AccountId]: checked };
      });
      const ObjectList = Object.assign({}, ...array);
      setAllAccounts(ObjectList);
    }
    if (list && propKey === 'EditAccountAlias' && customerAccountsContext.accountsCustomer) {
      JSON.stringify(list) === JSON.stringify(userPermissionsContext.viewableAccounts)
        ? setCheckedSelectAll(true)
        : setCheckedSelectAll(false);
      const array = userPermissionsContext.viewableAccounts.map((item) => {
        const checked = !!(list && list.includes(item));
        return { [item]: checked };
      });
      const ObjectList = Object.assign({}, ...array);
      setAllAccounts(ObjectList);
    }
    if ((list === false || list) && propKey === 'IsAdmin') {
      setAllAccounts({ [customersContext.activeCustomer]: list });
    }
  }, [list, userPermissionsContext.error, cancel]);
  const handleClick = (e) => {
    let selectedAccounts;
    if (propKey !== 'IsAdmin') {
      selectedAccounts = Object.keys(allAccounts).filter(function (k) {
        return allAccounts[k];
      });
    } else if (propKey === 'IsAdmin') {
      selectedAccounts = allAccounts[customersContext.activeCustomer];
    }
    e.preventDefault();
    userPermissionsContext.editUserPermissions(
      customersContext.activeCustomer,
      userPermissionsContext.selectedUser,
      propKey,
      selectedAccounts
    );
    setDisabled(true);
  };
  const handleCancel = (e) => {
    e.preventDefault();
    setCancel(!cancel);
    setDisabled(true);
  };
  let newState = {};
  const handleOnChangeSelectAll = () => {
    checkedSelectAll
      ? setAllAccounts((prevState) => {
          Object.keys(prevState).map((item) => (newState[item] = false));
          return newState;
        })
      : setAllAccounts((prevState) => {
          Object.keys(prevState).map((item) => (newState[item] = true));
          return newState;
        });
    setCheckedSelectAll(!checkedSelectAll);
    disableButton();
  };
  const disableButton = () => {
    let obj = {};
    checkedSelectAll
      ? Object.keys(allAccounts).map((item) => (obj[item] = false))
      : Object.keys(allAccounts).map((item) => (obj[item] = true));
    JSON.stringify(Object.keys(obj).filter((item) => obj[item])) === JSON.stringify(list)
      ? setDisabled(true)
      : setDisabled(false);
  };
  const handleChange = (e) => {
    setAllAccounts({ ...allAccounts, [e.target.name]: e.target.checked });
    const changedAccountObj = { ...allAccounts, [e.target.name]: e.target.checked };
    Object.values(changedAccountObj).every((value) => value === true)
      ? setCheckedSelectAll(true)
      : setCheckedSelectAll(false);
    let checkedArray;
    if (list && propKey !== 'IsAdmin') {
      checkedArray = Object.keys(changedAccountObj).filter(function (k) {
        return changedAccountObj[k];
      });
    } else if (propKey === 'IsAdmin') {
      checkedArray = changedAccountObj[customersContext.activeCustomer];
    }
    JSON.stringify(checkedArray) === JSON.stringify(list) ? setDisabled(true) : setDisabled(false);
  };
  const getLabel = (key) => {
    if (propKey === 'IsAdmin') {
      return key;
    } else {
      const foundAccount = customerAccountsContext.accountsCustomer.find(
        (x) => x.AccountId === key
      );
      return foundAccount && foundAccount.Alias ? `${foundAccount.Alias} (${key})` : key;
    }
  };
  const showSelectAll = () => {
    if (allAccounts && propKey !== 'IsAdmin') {
      if (propKey === 'ViewAccounts') {
        return true;
      }
      if (propKey === 'EditAccountAlias') {
        return !!(
          userPermissionsContext.viewableAccounts &&
          userPermissionsContext.viewableAccounts.length > 0
        );
      }
    } else return false;
  };
  return (
    <form>
      {showSelectAll() && (
        <TdsCheckbox
          checkboxId={`${propKey}-selectAll`}
          checked={checkedSelectAll}
          onTdsChange={handleOnChangeSelectAll}>
          <div slot={'label'}>Select All</div>
        </TdsCheckbox>
      )}
      {allAccounts &&
        Object.keys(allAccounts).length !== 0 &&
        Object.entries(allAccounts).map(([key, value]) => (
          <TdsCheckbox
            key={key}
            type="checkbox"
            name={key}
            checkboxId={`${propKey}-${key}`}
            value={key}
            onTdsChange={handleChange}
            checked={value}>
            <div slot={'label'} className={blurIfEnabled('')}>
              {getLabel(key)}
            </div>
          </TdsCheckbox>
        ))}
      <div className="tds-u-flex tds-u-flex-end tds-u-gap1 tds-u-mt1">
        <TdsButton
          onClick={handleCancel}
          disabled={disabled}
          size={'sm'}
          text={'Reset'}></TdsButton>
        <TdsButton
          type="submit"
          size={'sm'}
          onClick={handleClick}
          disabled={disabled}
          text={'Save'}></TdsButton>
      </div>
    </form>
  );
};

export default PermissionsActionsForm;
