import { styled } from 'styled-components';
import React from 'react';

const BaseHttpLabel = styled.span`
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 10px;
`;

const PostLabel = styled(BaseHttpLabel)`
  background-color: var(--tds-blue-500);
`;

const PutLabel = styled(BaseHttpLabel)`
  background-color: var(--tds-blue-700);
`;

const GetLabel = styled(BaseHttpLabel)`
  background-color: var(--tds-green-500);
`;

const PatchLabel = styled(BaseHttpLabel)`
  background-color: var(--tds-orange-500);
`;

const DeleteLabel = styled(BaseHttpLabel)`
  background-color: var(--tds-red-500);
`;

function renderMethodLabel(method) {
  let lowerCaseMethod = method.toLowerCase();
  switch (lowerCaseMethod) {
    case 'post':
      return <PostHttpLabel>POST</PostHttpLabel>;
    case 'get':
      return <GetHttpLabel>GET</GetHttpLabel>;
    case 'patch':
      return <GetHttpLabel>PATCH</GetHttpLabel>;
    case 'delete':
      return <DeleteHttpLabel>DELETE</DeleteHttpLabel>;
    case 'put':
      return <PutHttpLabel>PUT</PutHttpLabel>;
    default:
      return <></>;
  }
}

export function HttpMethodLabel({ method }) {
  return renderMethodLabel(method);
}

export function PostHttpLabel({ children }) {
  return <PostLabel>{children}</PostLabel>;
}

export function GetHttpLabel({ children }) {
  return <GetLabel>{children}</GetLabel>;
}

export function PatchHttpLabel({ children }) {
  return <PatchLabel>{children}</PatchLabel>;
}

export function PutHttpLabel({ children }) {
  return <PutLabel>{children}</PutLabel>;
}

export function DeleteHttpLabel({ children }) {
  return <DeleteLabel>{children}</DeleteLabel>;
}
