import MainHeader from '../../components/atoms/Typography/MainHeader';
import React from 'react';

function Glossary() {
  return (
    <div className={'tds-container'}>
      <div className={'tds-row'}>
        <div className={'tds-col'}>
          <MainHeader>Glossary</MainHeader>
          <ul>
            <li>
              <h4>Carbon Footprint</h4>
              <p>
                The total amount of greenhouse gases, released into the atmosphere as a result of
                your operations, measured in grams or kilograms of {'CO\u2082e'}.
              </p>
            </li>
            <li>
              <h4>Greenhouse Gases</h4>
              <p>
                Gases that trap heat in the earth&apos;s atmosphere, contributing to global warming
                and climate change.
              </p>
            </li>
            <li>
              <h4>Energy</h4> The calculations in CET take into consideration the energy used to
              power a data centre and network communications. Factors that affect the energy usage
              include the type of computer you use, and auxiliary systems such as cooling and
              lighting. Measured in kilowatt-hours (kWh).
            </li>
            <li>
              <h4>Data Centre</h4>
              <p>
                The physical facility that houses the computer hardware, for example: servers,
                storage networking equipment.
              </p>
            </li>
            <li>
              <h4>Carbon Intensity</h4>
              <p>
                A measurement of the amount of emissions created per unit of energy. Measured in
                {'CO\u2082e'}/kWh.
              </p>
            </li>
            <li>
              <h4>Power Usage Effectiveness (PUE)</h4>
              <p>
                A measurement of the efficiency of a data centre. A ratio of total energy used to
                energy used by the IT equipment.
              </p>
            </li>
            <li>
              <h4>Embodied Carbon</h4>
              <p>
                The term given to the emissions associated upstream stages of a product, for
                example: manufacturing and transportation.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Glossary;
