import React, { useContext } from 'react';
import DateFilterContext from '../../../../contexts/FilterContexts/DateFilterContext';
import ProvidersFilter from '../../../../components/organisms/Filters/ProvidersFilter';
import AccountsFilter from '../../../../components/organisms/Filters/AccountsFilter/AccountsFilter';
import ServicesFilter from '../../../../components/organisms/Filters/ServicesFilter';
import CategoriesFilter from '../../../../components/organisms/Filters/CategoriesFilter';
import DateFilter from '../../../../components/organisms/Filters/DateFilter/DateFilter';

function DetailedEmissionsFilter({
  filterId,
  getIfMultiSelecionEnabled,
  selectedDates,
  setSelectedDates,
  handleDateFilterChange
}) {
  const dateFilterContext = useContext(DateFilterContext);

  return (
    <div className={'tds-u-flex tds-u-flex-wrap tds-u-gap1'}>
      <ProvidersFilter
        controlId={'ProvidersDetailed'}
        filterId={filterId}
        closeOnClickOutside={true}
      />
      <AccountsFilter
        filterId={filterId}
        closeOnClickOutside={true}
        multiSelection={getIfMultiSelecionEnabled()}
        selectAllEnabled={getIfMultiSelecionEnabled()}
        applyFilterButtonEnabled={getIfMultiSelecionEnabled()}
      />
      <ServicesFilter filterId={filterId} closeOnClickOutside={true} />
      <CategoriesFilter filterId={filterId} closeOnClickOutside={true} />
      <DateFilter
        id="emissions"
        closeOnClickOutside={true}
        selectedDates={selectedDates}
        setSelectedDates={setSelectedDates}
        handleDateFilterChange={handleDateFilterChange}
        dateRange={dateFilterContext.dateRange}
      />
    </div>
  );
}

export default DetailedEmissionsFilter;
