import React from 'react';

function HeaderWithSubText({ text, subText }) {
  return (
    <div>
      <div className="tds-headline-03">{text}</div>
      <p>{subText}</p>
    </div>
  );
}

export default HeaderWithSubText;
