import {
  getCarbonUnitLabel,
  getEnergyUnitLabel,
  mapToHighestMagnitude
} from '../../../utilities/Units';
import { getElementCoords } from '../../../utilities/ElementCoordinates';
import { format, subDays } from 'date-fns';

export const getLineChartOptions = (data, filteredPeriod, onMouseMove) => {
  return {
    chart: {
      id: 'basic',
      events: {
        mouseMove: function (_, __, config) {
          // Set default data (null == disabled tooltip)
          let data = null;

          // Check if the mouse button is being pressed while moving (i.e. a zoom action is in progress)
          if (!document.body.matches(':active')) {
            // Get coordinates from the built-in tooltip (where Apex Chart calculates the tooltip to be placed)
            let apexChart = document.getElementById('apexchartsbasic');
            let tooltip = apexChart.querySelector('.apexcharts-tooltip');
            let tooltipCoords = getElementCoords(tooltip);

            // Determine if tooltip is shown and if it has a child element with id 'line-chart-tooltip'
            let tooltipShown = tooltip.classList.contains('apexcharts-active');
            let tooltipHasChild = tooltip.querySelector('#line-chart-tooltip') != null;

            // Generate data for the tooltip component and pass it to the onMouseMove callback function
            data = {
              left: tooltipCoords.left,
              right: tooltipCoords.right,
              top: tooltipCoords.top,
              bottom: tooltipCoords.bottom,
              tooltipDisplay: tooltipShown && tooltipHasChild,
              series: config.globals.series,
              seriesLabels: config.globals.seriesNames,
              seriesColors: config.globals.seriesColors,
              seriesMaxScales: config.globals.yRange,
              dataPointIndex: config.dataPointIndex,
              dataPointLabels: config.globals.categoryLabels,
              dataPointVisibleLabelIndexes: config.globals.labels
            };
          }

          // Propagate event further down with the data
          onMouseMove(data);
        }
      },
      redrawOnWindowResize: true,
      redrawOnParentResize: true,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: []
        },
        export: {
          csv: {
            filename: 'CETCarbonData'
          },
          svg: {
            filename: 'CETCarbonGraph'
          },
          png: {
            filename: 'CETCarbonGraph'
          }
        }
      }
    },
    labels: data.Labels,
    legend: {
      show: true
    },
    grid: {
      show: true,
      borderColor: '#DDDDDD',
      strokeDashArray: 0,
      position: 'back',
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      },
      row: {
        colors: [undefined, '#EEEEEE'],
        opacity: 0.5
      },
      column: {
        colors: undefined,
        opacity: 0.2
      }
    },
    yaxis: [
      {
        show: true,
        showForNullSeries: true,
        labels: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          formatter: function (val) {
            if (val) {
              return val.toFixed(2);
            }
            return val;
          }
        },
        max: function (max) {
          return max + 2;
        },
        forceNiceScale: true,
        title: {
          text: `CO₂e emissions (${getCarbonUnitLabel(data.Carbon)})`,
          style: {
            color: 'var(--gaia-color-primary-500)'
          }
        },
        seriesName: `CO₂e emissions (${getCarbonUnitLabel(data.Carbon)})`
      },
      {
        show: true,
        showForNullSeries: true,
        labels: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          formatter: function (val) {
            if (val) {
              return val.toFixed(2);
            }
            return val;
          }
        },
        max: function (max) {
          return max + 2;
        },
        forceNiceScale: true,
        title: {
          text: `Energy (${getEnergyUnitLabel(data.Energy)})`,
          style: {
            color: 'var(--tds-grey-400)'
          }
        },
        seriesName: `Energy (${getEnergyUnitLabel(data.Energy)})`,
        opposite: true
      }
    ],
    forecastDataPoints: {
      count: getDashedLine(filteredPeriod.to) ? 1 : 0
    },
    tooltip: {
      // Specify an "empty" custom tooltip that's hidden (d-none) with the id so it can be accessed later (line-chart-tooltip)
      custom: () => {
        return '<div id="line-chart-tooltip" class="d-none" />';
      }
    }
  };
};

export const getChartSeries = (carbonPoints, energyPoints) => {
  // Protect from runtime error when 'undefined' by sending in empty arrays
  if (carbonPoints === undefined) {
    carbonPoints = [];
  }
  if (energyPoints === undefined) {
    energyPoints = [];
  }

  return [
    {
      color: 'var(--tds-grey-700)',
      name: `CO₂e emissions (${getCarbonUnitLabel(carbonPoints)})`,
      data: mapToHighestMagnitude(carbonPoints)
    },
    {
      color: 'var(--tds-grey-400)',
      name: `Energy (${getEnergyUnitLabel(energyPoints)})`,
      data: mapToHighestMagnitude(energyPoints)
    }
  ];
};
function getDashedLine(endfilterDate) {
  return endfilterDate === format(subDays(new Date(), 1), 'yyyy-MM-dd');
}
