function ErrorNotFound() {
  return (
    <div className="tds-container-fluid">
      <div className="tds-row">
        <div className={'tds-col'}>
          <h1>Ooops...</h1>
          <p>
            Unfortunately the requested content cannot be found!
            <br />
            <br />
          </p>
        </div>
      </div>
    </div>
  );
}

export default ErrorNotFound;
