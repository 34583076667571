import { createContext } from 'react';
import FilterContextBase from './FilterContextBase';

export const CategoriesFilterContext = createContext();

export class CategoriesFilterContextProvider extends FilterContextBase {
  // Constructor
  constructor(props) {
    super(props, CategoriesFilterContext, Object.keys({ CategoriesFilterContext })[0]);

    // Define default state (extended from parent) - Using "incorrect" way of setting state (not using "setState") is OK since it's being defined in constructor
    this.state = { ...this.state, ...{ isFetchingItems: true, fetchItems: this.fetchItems } };
  }

  fetchItems = () => {
    this.setState({ isFetchingItems: true });

    this.setItems([
      {
        id: 'CarbonCompute',
        label: 'CO₂e Compute',
        selected: true
      },
      {
        id: 'CarbonStorage',
        label: 'CO₂e Storage',
        selected: true
      },
      {
        id: 'CarbonNetwork',
        label: 'CO₂e Network',
        selected: true
      },
      {
        id: 'CarbonMemory',
        label: 'CO₂e Memory',
        selected: true
      },
      {
        id: 'CarbonEmbodied',
        label: 'CO₂e Embodied',
        selected: false
      },
      {
        id: 'Energy',
        label: 'Energy',
        selected: true,
        disabled: true
      }
    ]);

    this.setState({ isFetchingItems: false });
  };
}

// Set default properties
CategoriesFilterContextProvider.defaultProps = {
  children: null
};

export default CategoriesFilterContext;
