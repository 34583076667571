import React from 'react';
import HomeDashboard from './Components/HomeDashboard';
import { RegionalEmissionsProvider } from '../../contexts/Emissions/RegionalEmissionsContext';

function Emissions() {
  return (
    <RegionalEmissionsProvider>
      <HomeDashboard></HomeDashboard>
    </RegionalEmissionsProvider>
  );
}

export default Emissions;
