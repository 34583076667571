import React from 'react';
import config from '../../../../config';
import { TdsBlock } from '@scania/tegel-react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/src/styles/hljs';
import DivTdsBody01 from '../../../../components/atoms/Typography/DivTdsBody01';
import MainHeader from '../../../../components/atoms/Typography/MainHeader';

const exampleRequest = {
  Period: {
    Start: '2023-02-01',
    End: '2023-02-03'
  },
  PeriodGranularity: 'Daily',
  Customers: ['YourCustomerID'],
  Providers: ['AWS'],
  GroupBy: ['PERIOD'],
  Accounts: ['YourAccountID']
};

const exampleResponse = {
  Period: {
    Start: '2023-02-01',
    End: '2023-02-03'
  },
  Customers: ['YourCustomerID'],
  Providers: ['AWS'],
  PeriodGranularity: 'Daily',
  Accounts: ['YourAccountID'],
  GroupBy: ['PERIOD'],
  Data: [
    {
      Period: '2023-02-01',
      CarbonCompute: 14,
      CarbonNetwork: 3.14,
      CarbonStorage: 1.9,
      CarbonMemory: 0.0,
      CarbonEmbodied: 137,
      Energy: 60
    },
    {
      Period: '2023-02-02',
      CarbonCompute: 14,
      CarbonNetwork: 3.1415,
      CarbonStorage: 1.987,
      CarbonMemory: 0.0,
      CarbonEmbodied: 137,
      Energy: 60
    }
  ]
};

const DevelopersDataApiExample = () => {
  return (
    <TdsBlock>
      <MainHeader>Example</MainHeader>
      <DivTdsBody01>Get the daily emissions for all your accounts.</DivTdsBody01>
      <h3>Request</h3>
      <SyntaxHighlighter language="sh" style={docco} wrapLongLines>
        {"curl '" +
          config.API_GATEWAY_EMISSIONS_URL +
          '/v1/data\' -X POST -H "Authorization: Basic $API_KEY" ' +
          `-d '${JSON.stringify(exampleRequest)}'`}
      </SyntaxHighlighter>
      <h3>Response</h3>
      <h4>Status Codes</h4>
      <code>200 | 400 | 401</code>
      <h4>Payload Data (200)</h4>
      <SyntaxHighlighter language="json" style={docco} wrapLongLines>
        {JSON.stringify(exampleResponse, null, 2)}
      </SyntaxHighlighter>
    </TdsBlock>
  );
};

export default DevelopersDataApiExample;
