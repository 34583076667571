import React, { createContext, useContext } from 'react';
import { API } from 'aws-amplify';
import CustomerAccountsContext from '../Customer/CustomerAccountsContext';

export const AccountAliasContext = createContext();

export const AccountAliasProvider = (props) => {
  const customerAccountsContext = useContext(CustomerAccountsContext);
  function deleteAlias(account, onSuccess, onError) {
    API.del(`customer`, `/v1/customers/${account.CustomerId}/accounts/${account.AccountId}/alias`)
      .then(() => {
        //update the customer accounts state after deleting the alias
        customerAccountsContext.setAccountAlias(account.AccountId, null);
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(() => {
        if (onError) {
          onError();
        }
      });
  }
  function setAlias(account, newAlias, onSuccess, onError) {
    if (!account.Alias) {
      API.post(
        `customer`,
        `/v1/customers/${account.CustomerId}/accounts/${account.AccountId}/alias`,
        { body: { Alias: newAlias } }
      )
        .then(() => {
          //update the customer accounts state after creating the alias
          customerAccountsContext.setAccountAlias(account.AccountId, newAlias);
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch(() => {
          if (onError) {
            onError();
          }
        });
    } else {
      API.put(
        `customer`,
        `/v1/customers/${account.CustomerId}/accounts/${account.AccountId}/alias`,
        { body: { Alias: newAlias } }
      )
        .then(() => {
          //update the customer accounts state after updating the alias
          customerAccountsContext.setAccountAlias(account.AccountId, newAlias);
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch(() => {
          if (onError) {
            onError();
          }
        });
    }
  }

  const state = {
    deleteAlias: deleteAlias,
    setAlias: setAlias
  };

  return (
    <AccountAliasContext.Provider value={state}>{props.children}</AccountAliasContext.Provider>
  );
};

export default AccountAliasContext;
