import React, { createContext, useEffect, useState, useContext } from 'react';
import { API } from 'aws-amplify';
import AuthContext from '../common/AuthContext';
import logger from '../../logger';

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [email, setEmail] = useState(undefined);
  const [associatedCustomerIds, setAssociatedCustomerIds] = useState(undefined);
  const [adminCustomerIds, setAdminCustomerIds] = useState(undefined);
  const [groupIds, setGroupIds] = useState(undefined);

  const authContext = useContext(AuthContext);

  useEffect(() => {
    getCurrentUser();
    const interval = setInterval(getCurrentUser, 300000);
    //UseEffect clean up function - Whenever the effect runs, the clean up function runs before the useEffect function (except for the very first time) and it also runs whenever the component unmounts.
    return () => clearInterval(interval);
  }, [authContext.initials]);

  const getCurrentUser = () => {
    if (authContext.initials !== undefined && authContext.initials !== null) {
      API.get(`auth`, `/v1/auth/user`)
        .then((response) => {
          logger(`Current user: ${JSON.stringify(response)}`);
          setEmail(response ? response.Email : null);
          setAssociatedCustomerIds(response ? response.AssociatedCustomerIds : null);
          setAdminCustomerIds(response ? response.AdminCustomerIds : null);
          setGroupIds(response ? response.GroupIds : null);
        })
        .catch((error) => {
          logger(`STATUS: ${error.response.status}`);
          logger(`BODY: ${error.response.data.message}`);
          setEmail(null);
          setAssociatedCustomerIds(null);
          setAdminCustomerIds(null);
          setGroupIds(null);
        });
    }
  };

  const state = {
    email,
    associatedCustomerIds,
    adminCustomerIds,
    groupIds
  };

  return <UserContext.Provider value={state}>{props.children}</UserContext.Provider>;
};

export default UserContext;
