import React, { createContext, useContext, useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import UserContext from './UserContext';
import logger from '../../logger';

export const ApiUserContext = createContext();

export const ApiUserContextProvider = (props) => {
  const apiUserOnePrefix = 'APIUSER_1_';
  const apiUserTwoPrefix = 'APIUSER_2_';

  const [apiKeyOne, setApiKeyOne] = useState(undefined);
  const [apiKeyTwo, setApiKeyTwo] = useState(undefined);
  const [apiUserOne, setApiUserOne] = useState(undefined);
  const [apiUserTwo, setApiUserTwo] = useState(undefined);
  const [counterBackendCalls, setCounterBackendCalls] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const userContext = useContext(UserContext);

  // Fetch API users on initial rendering
  useEffect(() => {
    fetchApiUserOne();
    fetchApiUserTwo();
  }, []);

  // Fetch API users when user email changes or is set (will be delayed from initial rendering if page is reloaded from F5 e.g.)
  useEffect(() => {
    fetchApiUserOne();
    fetchApiUserTwo();
  }, [userContext.email]);

  const fetchApiUserOne = () => {
    fetchApiUser(apiUserOnePrefix);
  };
  const fetchApiUserTwo = () => {
    fetchApiUser(apiUserTwoPrefix);
  };
  const createApiUserOne = () => {
    createApiUser(apiUserOnePrefix);
  };
  const createApiUserTwo = () => {
    createApiUser(apiUserTwoPrefix);
  };
  const deleteApiUserOne = () => {
    deleteApiUser(apiUserOnePrefix);
  };
  const deleteApiUserTwo = () => {
    deleteApiUser(apiUserTwoPrefix);
  };

  const fetchApiUser = (prefix) => {
    // Just leave if email is not yet fetched from UserContext
    if (userContext.email === undefined) {
      return;
    }

    // Just leave if prefix is invalid
    if (prefix !== apiUserOnePrefix && prefix !== apiUserTwoPrefix) {
      logger(`Unknown API User Prefix: ${prefix}`);
      return;
    }

    let api_user = undefined;

    // Increase number of backend calls that are being performed
    setCounterBackendCalls((prevState) => prevState + 1);

    API.get(`auth`, `/v1/auth/api_user/${prefix}${userContext.email}`)
      .then((response) => {
        logger('fetchApiUser - SUCCESS', prefix, response); // TODO: Remove log row

        api_user = response;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          api_user = null;
        } else {
          logger('fetchApiUser - FAILURE', prefix, JSON.stringify(error));
        }
      })
      .finally(() => {
        switch (prefix) {
          case apiUserOnePrefix:
            setApiUserOne(api_user);
            break;
          case apiUserTwoPrefix:
            setApiUserTwo(api_user);
            break;
        }

        // Decrease number of backend calls that are being performed
        setCounterBackendCalls((prevState) => prevState - 1);
        setIsLoading(false);
      });
  };

  const createApiUser = (prefix) => {
    // Just leave if email is not yet fetched from UserContext
    if (userContext.email === undefined) {
      return;
    }

    // Just leave if prefix is invalid
    if (prefix !== apiUserOnePrefix && prefix !== apiUserTwoPrefix) {
      logger(`Unknown API User Prefix: ${prefix}`);
      return;
    }

    let api_key = undefined;

    // Increase number of backend calls that are being performed
    setCounterBackendCalls((prevState) => prevState + 1);

    API.post(`auth`, `/v1/auth/api_user/${prefix}${userContext.email}`)
      .then((response) => {
        logger('createApiUser - SUCCESS', prefix, response); // TODO: Remove log row

        api_key = response.APIKey;
      })
      .catch((error) => {
        logger('createApiUser - FAILURE', prefix, JSON.stringify(error));
      })
      .finally(() => {
        switch (prefix) {
          case apiUserOnePrefix:
            setApiKeyOne(api_key);
            fetchApiUserOne();
            break;
          case apiUserTwoPrefix:
            setApiKeyTwo(api_key);
            fetchApiUserTwo();
            break;
        }

        // Decrease number of backend calls that are being performed
        setCounterBackendCalls((prevState) => prevState - 1);
      });
  };

  const deleteApiUser = (prefix) => {
    // Just leave if email is not yet fetched from UserContext
    if (userContext.email === undefined) {
      return;
    }

    // Just leave if prefix is invalid
    if (prefix !== apiUserOnePrefix && prefix !== apiUserTwoPrefix) {
      logger(`Unknown API User Prefix: ${prefix}`);
      return;
    }

    let api_user = undefined;

    // Increase number of backend calls that are being performed
    setCounterBackendCalls((prevState) => prevState + 1);

    API.del(`auth`, `/v1/auth/api_user/${prefix}${userContext.email}`)
      .then((response) => {
        logger('deleteApiUser - SUCCESS', prefix, response); // TODO: Remove log row

        api_user = null;
      })
      .catch((error) => {
        if (error.response.status === 409) {
          api_user = null;
        } else {
          logger('deleteApiUser - FAILURE', prefix, JSON.stringify(error));
        }
      })
      .finally(() => {
        switch (prefix) {
          case apiUserOnePrefix:
            setApiUserOne(api_user);
            break;
          case apiUserTwoPrefix:
            setApiUserTwo(api_user);
            break;
        }

        // Decrease number of backend calls that are being performed
        setCounterBackendCalls((prevState) => prevState - 1);
      });
  };

  const state = {
    resetKeyOne: () => setApiKeyOne(undefined),
    resetKeyTwo: () => setApiKeyTwo(undefined),
    apiKeyOne,
    apiKeyTwo,
    apiUserOne,
    apiUserTwo,
    isLoading,
    fetchApiUserOne,
    fetchApiUserTwo,
    createApiUserOne,
    createApiUserTwo,
    deleteApiUserOne,
    deleteApiUserTwo,
    isCallingBackend: () => counterBackendCalls > 0
  };

  return <ApiUserContext.Provider value={state}>{props.children}</ApiUserContext.Provider>;
};

export default ApiUserContext;
