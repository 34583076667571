import React, { useContext, useEffect, useState } from 'react';
import UserPermissionsContext from '../../../contexts/Permissions/UserPermissionsContext';
import UserContext from '../../../contexts/Auth/UserContext';
import { styled } from 'styled-components';
import {
  TdsBodyCell,
  TdsHeaderCell,
  TdsTable,
  TdsTableBody,
  TdsTableBodyRow,
  TdsTableHeader,
  TdsTableToolbar
} from '@scania/tegel-react';
import useLocalStorage from 'use-local-storage';

const SizedAndScrollableDiv = styled.div`
  overflow-y: scroll;
  max-height: 40rem;
`;

const UsersTable = ({ usersList, isActive, setIsActive }) => {
  const [rowData, setRowData] = useState(undefined);
  const userPermissionContext = useContext(UserPermissionsContext);
  const usersContext = useContext(UserContext);
  const [blurSensitive] = useLocalStorage('blur', false);
  const blurIfEnabled = (classes) => (blurSensitive ? classes + ' blur' : classes);
  const rawToTableItem = (item) => {
    return {
      email: item.Id,
      permission: item.HasAccountViewAccess
    };
  };
  useEffect(() => {
    setRowData(usersList?.map(rawToTableItem));
  }, [usersList, isActive]);

  const handleFilter = (e) => {
    const query = e.detail.query;
    const filteredData = usersList.filter((object) => object.Id.includes(query.toLowerCase()));
    setRowData(filteredData?.map(rawToTableItem));
  };

  const handleSort = (event) => {
    const direction = event.detail.sortingDirection;

    const rowDataCopy = Array.from(rowData);
    rowDataCopy.sort((a, b) => {
      const comparison = a['email'].localeCompare(b['email']);
      return direction === 'desc' ? comparison * -1 : comparison;
    });
    setRowData(rowDataCopy);
  };

  const onRowSelect = (e, user) => {
    // handleClick(user['email']);
    setIsActive({ status: true, selectedUser: user['email'] });
    userPermissionContext.getUserPermissions(usersContext.adminCustomerIds[0], user['email']);
  };

  return (
    <>
      <div className="tds-u-flex flex-grow-1 tds-u-flex-dir-col" style={{ height: '90%' }}>
        <SizedAndScrollableDiv>
          <TdsTable compactDesign responsive tableId={'selectableTable'}>
            <TdsTableToolbar
              onTdsFilter={handleFilter}
              tableTitle="Filter"
              filter></TdsTableToolbar>

            <TdsTableHeader>
              <TdsHeaderCell
                sortable
                onTdsSort={handleSort}
                cellKey={'email'}
                cellValue={'User email'}></TdsHeaderCell>
            </TdsTableHeader>
            <TdsTableBody>
              {(rowData || []).map((user, index) => {
                return (
                  <TdsTableBodyRow
                    selected={isActive.selectedUser === user['email']}
                    onClick={(e) => onRowSelect(e, user)}
                    key={index}>
                    <TdsBodyCell className={blurIfEnabled()} cellKey={user['email']}>
                      {user['email']}
                    </TdsBodyCell>
                  </TdsTableBodyRow>
                );
              })}
            </TdsTableBody>
          </TdsTable>
        </SizedAndScrollableDiv>
      </div>
    </>
  );
};

export default UsersTable;
