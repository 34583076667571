import { format, addDays } from 'date-fns';

export const getDateString = (date, end, onlyDate = false) => {
  let dateString;
  if (end && format(date, 'dd-MM-yyyy') !== format(new Date(), 'dd-MM-yyyy')) {
    dateString = new Date(addDays(date, 1).setHours(0, 0, 0, 0));
  } else {
    dateString = new Date(date.setHours(0, 0, 0, 0));
  }
  dateString = new Date(dateString.getTime() - dateString.getTimezoneOffset() * 60000);
  dateString = onlyDate ? dateString.toISOString().slice(0, 10) : dateString.toISOString();
  return dateString;
};

export const getDaysArray = (start, end) => {
  let arr = [];
  for (var dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
    arr.push(getDateString(dt).slice(0, 10));
  }
  arr.pop();
  return arr;
};
