import React, { createContext, useState, useContext } from 'react';
import { API } from 'aws-amplify';
import LoadingContext from '../common/LoadingContext';

export const CustomerUsersContext = createContext();

export const CustomerUsersProvider = (props) => {
  const [customerId, setCustomerId] = useState(undefined);
  const [users, setUsers] = useState(undefined);
  const loadingContext = useContext(LoadingContext);

  function getUsers(customer_id) {
    loadingContext.startActivity(`getusers`);
    API.get(`auth`, `/v1/auth/customers/${customer_id}/users?include_has_account_view_access=true`)
      .then((resp) => {
        setCustomerId(resp ? resp.CustomerId : null);
        setUsers(resp ? resp.Users : null);
      })
      .catch(() => {})
      .finally(() => {
        loadingContext.stopActivity(`getusers`);
      });
  }

  const state = {
    getUsers,
    customerId,
    users,
    setUsers
  };

  return (
    <CustomerUsersContext.Provider value={state}>{props.children}</CustomerUsersContext.Provider>
  );
};

export default CustomerUsersContext;
