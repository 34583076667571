/**
 * Returns the magnitude of a value so that we can convert to kilo- or mega- units.
 * @param data a number
 * @returns {number}
 */
export function getMagnitude(data) {
  if (typeof data == 'object') {
    return calculateMagnitude(getMax(data));
  }
  return calculateMagnitude(data);
}

const decimalPlaces = 2;

/**
 * Converts the amount to a kilo-equivalent with 2 decimal places if the amount is higher than 1000.
 * Otherwise, returns the original amount.
 * @param amount
 * @returns {string}
 */
export function adjustMagnitude(amount) {
  const magnitude = getMagnitude(amount);
  return (amount / magnitude).toFixed(decimalPlaces);
}

/**
 * Adjusts all data points to the same order or magnitude.
 * @param dataPoints list of data points
 */
export function mapToHighestMagnitude(dataPoints) {
  const magnitude = getMagnitude(dataPoints);
  return dataPoints.map((point) =>
    point === null ? point : (point / magnitude).toFixed(decimalPlaces)
  );
}

export function getCarbonUnitLabel(data) {
  const magnitude = getMagnitude(data);
  switch (magnitude) {
    case 1:
      return 'g';
    case 1000:
      return 'kg';
    case 1000000:
      return 't';
    default:
      return 'g';
  }
}

export function getEnergyUnitLabel(data) {
  const magnitude = getMagnitude(data);
  switch (magnitude) {
    case 1:
      return 'Wh';
    case 1000:
      return 'kWh';
    case 1000000:
      return 'MWh';
    default:
      return 'Wh';
  }
}

function calculateMagnitude(value) {
  if (value >= 1000000) {
    return 1000000;
  } else if (value >= 1000) {
    return 1000;
  } else {
    return 1;
  }
}

function getMax(dataPoints) {
  return Math.max.apply(Math, dataPoints);
}
