import { subDays } from 'date-fns';
import React, { createContext, useState } from 'react';

export const TrendsFilterContext = createContext();

export const TrendsFilterProvider = (props) => {
  const [baseLineDate, setBaseLineDate] = useState([
    {
      startDate: subDays(new Date(), 16),
      endDate: subDays(new Date(), 9),
      key: 'selection'
    }
  ]);
  const [assessmentDate, setAssessmentDate] = useState([
    {
      startDate: subDays(new Date(), 8),
      endDate: subDays(new Date(), 1),
      key: 'selection'
    }
  ]);

  const state = {
    baseLineDate,
    assessmentDate,
    setBaseLineDate,
    setAssessmentDate
  };

  return (
    <TrendsFilterContext.Provider value={state}>{props.children}</TrendsFilterContext.Provider>
  );
};
export default TrendsFilterContext;
