// Define variables
var mousePositionEventsRegistered = false;
var mousePositionY = 0;
var mousePositionX = 0;
var mousePositionHandlers = [];

// Functions

export const getMousePosition = () => {
  return { x: mousePositionX, y: mousePositionY };
};

export const registerMousePositionHandler = (handlerId, handlerFunction) => {
  mousePositionHandlers[handlerId] = handlerFunction;
};

const onMousePositionUpdate = (e) => {
  let body = document.body;
  let docEl = document.documentElement;
  let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  mousePositionY = e.pageY - scrollTop;
  mousePositionX = e.pageX - scrollLeft;

  // Iterate all handlers and call them
  for (let handlerId in mousePositionHandlers) {
    mousePositionHandlers[handlerId](getMousePosition());
  }
};

const registerEvents = () => {
  if (mousePositionEventsRegistered === false) {
    // console.log('Registering mouse position events');
    document.addEventListener('mousemove', onMousePositionUpdate, false);
    document.addEventListener('mouseenter', onMousePositionUpdate, false);
    mousePositionEventsRegistered = true;
  }
};

// Register events
registerEvents();
