import React, { createContext, useState } from 'react';
import { useCookies } from 'react-cookie';

export const CookieContext = createContext();

export const CookieProvider = (props) => {
  const [cookies] = useCookies(['CETCookieConsentApp', 'FunctionalCookieConsent']);
  const [IsCookieConsent, setIsCookieConsent] = useState(cookies.CETCookieConsentApp);
  const [functionalCookies, setFunctionalCookies] = useState(!!cookies.FunctionalCookieConsent);
  const state = {
    IsCookieConsent,
    functionalCookies,
    setIsCookieConsent,
    setFunctionalCookies
  };
  return <CookieContext.Provider value={state}>{props.children}</CookieContext.Provider>;
};

export default CookieContext;
