import React, { createContext, useState, useContext } from 'react';
import { API } from 'aws-amplify';
import LoadingContext from '../common/LoadingContext';
import AlertContext from '../common/AlertContext';
import CustomerUsersContext from '../Auth/CustomerUsersContext';

export const UserPermissionsContext = createContext();

export const UserPermissionsProvider = (props) => {
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [viewableAccounts, setViewableAccounts] = useState(undefined);
  const [editableAccounts, setEditableAccounts] = useState(undefined);
  const [isAdmin, setIsAdmin] = useState(undefined);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const loadingContext = useContext(LoadingContext);
  const customerUsersContext = useContext(CustomerUsersContext);
  const alertContext = useContext(AlertContext);

  function getUserPermissions(customer_id, user_id) {
    loadingContext.startActivity(`getuserpermissions`, `Getting permissions for: ${user_id}`);
    API.get(`auth`, `/v1/auth/customers/${customer_id}/users/${user_id}`)
      .then((resp) => {
        setSelectedUser(resp ? resp.data.id : null);
        setViewableAccounts(resp ? resp.data.attributes.ViewAccounts : null);
        setEditableAccounts(resp ? resp.data.attributes.EditAccountAlias : null);
        setIsAdmin(resp ? resp.data.attributes.IsAdmin : null);
      })
      .catch(() => {})
      .finally(() => {
        loadingContext.stopActivity(`getuserpermissions`);
      });
  }
  function editUserPermissions(customer_id, user_id, propKey, accountCheckList) {
    loadingContext.startActivity(`Edit user permissions`, `Edit permissions for: ${user_id}`);
    const obj = { data: { attributes: { [propKey]: accountCheckList, CustomerId: customer_id } } };
    API.patch(`auth`, `/v1/auth/customers/${customer_id}/users/${user_id}`, {
      body: obj
    })
      .then(() => {
        if (propKey === 'ViewAccounts') {
          setViewableAccounts(accountCheckList);
          setEditableAccounts(editableAccounts.filter((item) => accountCheckList.includes(item)));
          if (accountCheckList?.length === 0) {
            customerUsersContext.setUsers(
              customerUsersContext.users.map((user) =>
                user.Id === user_id ? { ...user, HasAccountViewAccess: false } : user
              )
            );
          } else if (accountCheckList?.length > 0) {
            customerUsersContext.setUsers(
              customerUsersContext.users.map((user) =>
                user.Id === user_id ? { ...user, HasAccountViewAccess: true } : user
              )
            );
          }
        }
        if (propKey === 'EditAccountAlias') {
          setEditableAccounts(accountCheckList);
        }
        if (propKey === 'IsAdmin') {
          setIsAdmin(accountCheckList);
        }
        setSuccess(true);
        alertContext.addAlert('Account updated successfully!', false, () => setSuccess(false));
      })
      .catch(() => {
        setError(true);
        alertContext.addAlert('An error occurred!', true, () => setError(false));
      })
      .finally(() => {
        loadingContext.stopActivity(`Edit user permissions`, `Edit permissions for: ${user_id}`);
      });
  }

  const state = {
    getUserPermissions,
    selectedUser,
    viewableAccounts,
    editableAccounts,
    isAdmin,
    setViewableAccounts,
    setEditableAccounts,
    setIsAdmin,
    editUserPermissions,
    error,
    setError,
    success,
    setSuccess
  };

  return (
    <UserPermissionsContext.Provider value={state}>
      {props.children}
    </UserPermissionsContext.Provider>
  );
};

export default UserPermissionsContext;
