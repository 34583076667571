import React from 'react';
import useLocalStorage from 'use-local-storage';
import './GeneralSettings.css';
import HeaderWithSubText from '../../components/atoms/Typography/HeaderWithSubText';
import { TdsCheckbox } from '@scania/tegel-react';

function Settings() {
  const [blurSensitive, setBlurSensitive] = useLocalStorage('blur', false);
  const toggleBlueSensitive = () => {
    setBlurSensitive((prevState) => !prevState);
  };
  return (
    <>
      <div className="tds-container tds-u-mt3 tds-u-mb3">
        <div className="tds-row">
          <div>
            <HeaderWithSubText
              text="General Settings"
              subText={'Configure the Cloud Emissions Tracker dashboard'}
            />{' '}
          </div>
        </div>
        <hr className="horizontal-line-tag" />
        <div className="tds-row tds-u-mt3">
          <div className="tds-col">
            <TdsCheckbox checked={blurSensitive} onTdsChange={toggleBlueSensitive}>
              <div slot="label">
                <div>Blur sensitive data</div>
                <div className="general-settings-form-subtext">
                  Makes sensitive data hard to read (account IDs, subscription IDs, account aliases,
                  emails)
                </div>
              </div>
            </TdsCheckbox>
          </div>
        </div>
      </div>
    </>
  );
}

export default Settings;
