import React, { useContext, useEffect, useState } from 'react';
import UserPermissionsContext from '../../../contexts/Permissions/UserPermissionsContext';
import PermissionsActionsForm from './PermissionsActionsForm';
import useLocalStorage from 'use-local-storage';
import { TdsAccordion, TdsAccordionItem } from '@scania/tegel-react';

const PermissionsAccountActions = ({ isActive }) => {
  const userPermissionsContext = useContext(UserPermissionsContext);
  const [viewList, setViewList] = useState(null);
  const [editList, setEditList] = useState(null);
  const [adminInfo, setAdminInfo] = useState(null);
  const [blurSensitive] = useLocalStorage('blur', false);

  const blurIfEnabled = (classes) => (blurSensitive ? classes + ' blur' : classes);

  useEffect(() => {
    setViewList(userPermissionsContext.viewableAccounts);
    setEditList(userPermissionsContext.editableAccounts);
    setAdminInfo(userPermissionsContext.isAdmin);
  }, [
    userPermissionsContext.selectedUser,
    userPermissionsContext.viewableAccounts,
    userPermissionsContext.editableAccounts,
    userPermissionsContext.isAdmin,
    userPermissionsContext.error,
    userPermissionsContext.success
  ]);
  return (
    <div
      className={
        'px-3 ' +
        (isActive.status
          ? 'tds-col permissions-body-actions'
          : 'tds-col permissions-body-actions-disabled')
      }>
      <h4>
        Account actions{' '}
        {userPermissionsContext.selectedUser && (
          <>
            <span>for </span>
            <span className={blurIfEnabled('permissions-body-heading-span')}>
              {userPermissionsContext.selectedUser}
            </span>
          </>
        )}
      </h4>

      <TdsAccordion>
        <TdsAccordionItem header={'Accounts the user can view'}>
          <PermissionsActionsForm propKey="ViewAccounts" list={viewList} />
        </TdsAccordionItem>
        <TdsAccordionItem header={'Accounts the user can edit (aliases)'}>
          <PermissionsActionsForm propKey="EditAccountAlias" list={editList} />
        </TdsAccordionItem>
        <hr className="horizontal-line-tag" />
        <h4>Admin permissions</h4>

        <TdsAccordionItem header={'Accounts for which this user is admin'}>
          <PermissionsActionsForm propKey="IsAdmin" list={adminInfo} />
        </TdsAccordionItem>
      </TdsAccordion>
    </div>
  );
};

export default PermissionsAccountActions;
