import React, { useContext, useState } from 'react';
import AccountsFilterContext from '../../../../contexts/FilterContexts/AccountsFilterContext';
import ProvidersFilterContext from '../../../../contexts/FilterContexts/ProvidersFilterContext';
import FilterDropdown from '../../../molecules/Filter/FilterDropdown';
import FilterItemControl from '../../../molecules/Filter/FilterItemControl';
import HandleOutsideClickAndEsc from '../../../atoms/Handlers/HandleOutsideClickAndEsc';
import FilterDropdownButton from '../../../molecules/Filter/FilterDropdownButton';
import { AccountAliasProvider } from '../../../../contexts/accountAlias/AccountAliasContext';
import { useCookies } from 'react-cookie';
import { TdsIcon } from '@scania/tegel-react';
import AccountAliasModal from './AccountAlias/AccountAliasModal';

function AccountsFilter(props, { handlers = [] }) {
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [displayAliasModal, setDisplayAliasModal] = useState(false);
  const accountsFilterContext = useContext(AccountsFilterContext);
  const providersFilterContext = useContext(ProvidersFilterContext);
  const [error, setError] = useState(false);
  const [modalItemId, setModalItemId] = useState(undefined);
  const [cookies] = useCookies(['FunctionalCookieConsent']);

  const closeAliasModal = (closeTriggeredFromSave = false) => {
    if (closeTriggeredFromSave) {
      accountsFilterContext.fetchItems();
    }
    setDisplayAliasModal(false);
  };

  const onClickEditAlias = (itemId) => {
    setModalItemId(itemId);
    setDisplayAliasModal(true);
    setDisplayDropdown(false);
  };

  const shallBeDisplayed = (item) => {
    return item.canEdit;
  };

  const onClickDropdown = (openOnly) => {
    // Check if command is allowed to close dropdown as well, otherwise just return if already open
    if (openOnly === true && displayDropdown) {
      return;
    }

    setDisplayDropdown(!displayDropdown);
  };

  const closeDropdown = () => setDisplayDropdown(false);

  const getItemTypeLabel = () => {
    const provider = providersFilterContext.getSelectedItem();
    if (provider?.id === 'AWS') {
      return 'Accounts';
    } else if (provider?.id === 'AZURE') {
      return 'Subscriptions';
    } else {
      return 'Resource Boundaries';
    }
  };

  return (
    <HandleOutsideClickAndEsc enabled={props.closeOnClickOutside} handler={closeDropdown}>
      <FilterDropdownButton
        itemTypeLabel={getItemTypeLabel().toLowerCase()}
        onClickDropdownHandler={onClickDropdown}
        displayDropdown={displayDropdown}
      />
      <FilterDropdown
        controlId={props.controlId}
        filterId={props.filterId}
        handlers={[...handlers, closeDropdown]}
        displayed={displayDropdown}
        itemTypeLabel={getItemTypeLabel().toUpperCase()}
        itemsContextType={AccountsFilterContext}
        favouritesEnabled={cookies.FunctionalCookieConsent ? true : false}
        multiSelection={props.multiSelection}
        selectAllEnabled={props.selectAllEnabled}
        applyFilterButtonEnabled={props.applyFilterButtonEnabled}
        additionalItemControls={[
          <FilterItemControl
            key="ALIAS"
            onClick={onClickEditAlias}
            shallBeDisplayed={shallBeDisplayed}>
            <TdsIcon slot={'icon'} size={'20px'} name={'edit'} />
          </FilterItemControl>
        ]}
        emptyContent={
          <span>
            Please adjust your <b>PROVIDER</b> selection or talk to your administrator to get access
            to accounts/subscriptions
          </span>
        }
      />
      <AccountAliasProvider>
        <AccountAliasModal
          displayModal={displayAliasModal}
          handleDisplayModal={closeAliasModal}
          error={error}
          setError={setError}
          itemId={modalItemId}
        />
      </AccountAliasProvider>
    </HandleOutsideClickAndEsc>
  );
}

// Set default properties
// AccountsFilter.defaultProps = {
//   controlId: 'DEFAULT', // TODO: Autogenerate unique ID here
//   filterId: undefined,
//   handlers: [],
//   closeOnClickOutside: undefined
// };

export default AccountsFilter;
