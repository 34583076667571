import React from 'react';

function MobileViewScreen() {
  return (
    <div className="mobile-view-screen-container">
      <div className="empty-content-text-heading empty-content-spacing-heading">
        <br />
      </div>
      <div className="empty-content-text-bread empty-content-spacing-bread">
        <br />
        <br />
        For better user experience this page is avilable only in the desktop view
        <br />
      </div>
    </div>
  );
}

export default MobileViewScreen;
