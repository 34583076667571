import React, { useContext } from 'react';
import './CreateApiKey.css';
import ApiUserContext from '../../../contexts/Auth/ApiUserContext';
import config from './../../../config';
import { TdsButton } from '@scania/tegel-react';

const CreateApiKey = (props) => {
  const apiUserContext = useContext(ApiUserContext);

  const documentationClick = () => {
    window.open(config.DEVELOPERS_SITE, '_blank', 'noreferrer');
  };

  return (
    <div className="tds-row">
      <div className="tds-col">
        <div className="tds-headline-06">Create your first API Key</div>
        <ul>
          <li>
            In order to allow rotation of keys without any downtime we support two keys to be
            generated and active at the same time.
          </li>
          <li className="tds-u-mt2">
            When used, the keys will impersonate you and your permissions, meaning everything you
            can access in our application is also accessible with your API keys.
          </li>
          <li className="tds-u-mt2">
            <b>IMPORTANT:</b> Be sure to keep the keys safe, preferably encrypted and/or stored in
            an isolated storage!
          </li>
        </ul>
        <div>
          <TdsButton
            size="md"
            disabled={apiUserContext.isCallingBackend()}
            id="api-key-modal"
            text="Generate API key"
            onClick={props.handleClick}></TdsButton>
          <TdsButton
            variant="secondary"
            size="md"
            className="tds-u-ml1"
            disabled={apiUserContext.isCallingBackend()}
            onClick={documentationClick.bind(this)}
            text="Read Our Documentation"></TdsButton>
        </div>
      </div>
    </div>
  );
};

export default CreateApiKey;
