import React from 'react';
import { format } from 'date-fns';
import ArrowNarrowRightIcon from '../../../../atoms/SvgIcons/ArrowNarrowRightIcon';

const DateDropdownDisplay = (props) => {
  const getLabel = () => {
    if (props.showAsDate === true) {
      return (
        <div className={'tds-u-flex tds-u-gap1'}>
          <span> {format(props.dateRange[0].startDate, 'd MMM yyyy')} </span>
          <span>
            <ArrowNarrowRightIcon height="16" width="16" />
          </span>
          <span> {format(props.dateRange[0].endDate, 'd MMM yyyy')} </span>
        </div>
      );
    }

    if (props.id === 'assessment') {
      return 'Select Assessment Period';
    } else if (props.id === 'baseline') {
      return 'Select Baseline Period';
    } else {
      return 'Select Time Period';
    }
  };

  return <>{getLabel()}</>;
};

export default DateDropdownDisplay;
