import React, { createContext, useContext, useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import CustomersContext from './CustomersContext';
import logger from '../../logger';
import UserContext from '../Auth/UserContext';

export const CustomerAccountsContext = createContext();

export const CustomerAccountsProvider = (props) => {
  const [accounts, setAccounts] = useState(undefined);
  const [accountsCustomer, setAccountsCustomer] = useState(undefined);
  const customersContext = useContext(CustomersContext);
  const userContext = useContext(UserContext);
  useEffect(() => {
    const getAccounts = () => {
      if (customersContext.activeCustomer === null) {
        setAccounts([]);
      } else if (
        customersContext.activeCustomer !== undefined &&
        customersContext.activeCustomer !== null
      ) {
        API.get(`customer`, `/v1/customers/${customersContext.activeCustomer}/accounts/`)
          .then((response) => {
            // logger(`RESULT ACCOUNTS: ${JSON.stringify(response)}`);
            setAccounts(response);
          })
          .catch((error) => {
            logger(`STATUS: ${error.response.status}`);
            logger(`BODY: ${error.response.data.message}`);
            setAccounts(null);
          })
          .finally(() => {});
      }
    };

    getAccounts();
  }, [customersContext.activeCustomer]);

  useEffect(() => {
    const getAllAccounts = () => {
      if (
        customersContext.activeCustomer !== undefined &&
        customersContext.activeCustomer !== null
      ) {
        if (
          userContext.adminCustomerIds &&
          userContext.adminCustomerIds.length > 0 &&
          accountsCustomer === undefined
        ) {
          API.get(
            `customer`,
            `/v1/customers/${customersContext.activeCustomer}/accounts?filter=all`
          )
            .then((response) => {
              // logger(`RESULT ACCOUNTS ALLS: ${JSON.stringify(response)}`);
              setAccountsCustomer(response);
            })
            .catch((error) => {
              logger(`STATUS: ${error.response.status}`);
              logger(`BODY: ${error.response.data.message}`);
              setAccountsCustomer(null);
            })
            .finally(() => {});
        }
      }
    };
    getAllAccounts();
  }, [customersContext.activeCustomer, userContext.adminCustomerIds]);
  const getAccountById = (accountId) => {
    if (accounts) {
      for (const account of accounts) {
        if (account.AccountId === accountId) {
          return account;
        }
      }
    }
    return null;
  };
  const setAccountAlias = (accountId, newAlias) => {
    if (newAlias) {
      setAccounts(
        accounts.map((item) => {
          if (item.AccountId === accountId) return { ...item, Alias: newAlias };
          else return item;
        })
      );
    } else {
      setAccounts(
        accounts.map((item) => {
          if (item.AccountId === accountId) {
            delete item.Alias;
            return item;
          } else return item;
        })
      );
    }
  };
  const state = {
    accounts: accounts,
    accountsCustomer: accountsCustomer,
    getAccountById: getAccountById,
    setAccountAlias: setAccountAlias
  };

  return (
    <CustomerAccountsContext.Provider value={state}>
      {props.children}
    </CustomerAccountsContext.Provider>
  );
};

export default CustomerAccountsContext;
