import React from 'react';
import DivTdsBody01 from '../../../components/atoms/Typography/DivTdsBody01';
import MainHeader from '../../../components/atoms/Typography/MainHeader';

function RecommendationsRearchitecting() {
  return (
    <React.Fragment>
      <MainHeader id="rearchitecting">Re-architecting</MainHeader>
      <DivTdsBody01>
        There are many ways one can design or architect a system. Aim for architecture that uses the
        computer in the most optimal way while minimizing the {'CO\u2082e'} emissions.
      </DivTdsBody01>
      <DivTdsBody01>Some of these architectural changes might be:</DivTdsBody01>
      <ul>
        <li className={'tds-body-01'}>Choose serverless over servers</li>
        <li className={'tds-body-01'}>
          Choose energy-efficient programming languages (scientific study{' '}
          <a
            href="https://greenlab.di.uminho.pt/wp-content/uploads/2017/10/sleFinal.pdf"
            rel="noreferrer"
            target="_blank">
            here
          </a>
          )
        </li>
        <li className={'tds-body-01'}>
          Add jitter to batch job execution times so that it is not on whole hours (e.g. 04:00)
        </li>
        <li className={'tds-body-01'}>
          Run batch processes on times when there is little other traffic/jobs in the region to
          utilize resources that might be idling
        </li>
      </ul>
    </React.Fragment>
  );
}

export default RecommendationsRearchitecting;
