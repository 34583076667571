import React, { useContext } from 'react';
import { adjustMagnitude, getCarbonUnitLabel, getEnergyUnitLabel } from '../../../utilities/Units';
import CompanyTotalsContext from '../../../contexts/CompanyTotalsContext/CompanyTotalsContext';
import CustomerAccountsContext from '../../../contexts/Customer/CustomerAccountsContext';
import useLocalStorage from 'use-local-storage';
import AccountsFilterContext from '../../../contexts/FilterContexts/AccountsFilterContext';
import ProvidersFilterContext from '../../../contexts/FilterContexts/ProvidersFilterContext';
import ServicesFilterContext from '../../../contexts/FilterContexts/ServicesFilterContext';
import { TdsCard } from '@scania/tegel-react';

function DatePeriod(props) {
  return (
    <p>
      <b>{props.from}</b> to <b>{props.to}</b>
    </p>
  );
}

function ChartOverview(props) {
  const companyTotalsContext = useContext(CompanyTotalsContext);
  const customerAccountsContext = useContext(CustomerAccountsContext);
  const accountsFilterContext = useContext(AccountsFilterContext);
  const providersFilterContext = useContext(ProvidersFilterContext);
  const servicesFilterContext = useContext(ServicesFilterContext);
  const [blurSensitive] = useLocalStorage('blur', false);

  const getAccountsTooltip = () => {
    // Do not display tooltip for accounts if blur is enabled
    if (blurSensitive) {
      return 'Blurred since sensitivity mode is enabled';
    }

    const accountsSelected = accountsFilterContext.getSelectedItems();
    // when having only single account selection or if there are no accounts or only one account slected
    if (!accountsFilterContext.isAllowingMultipleSelection || accountsSelected.length <= 1) {
      return null;
    } else {
      let accountsString = '';
      accountsSelected.map((account) => {
        accountsString = `${accountsString}
${account.label} ${account.subLabel ? '(' + account.subLabel + ')' : ''}`;
      });
      return accountsString;
    }
  };

  const getNumberOfServices = () => {
    let noOfServiceSelected;
    const totalServices = servicesFilterContext.getAllItems().length;

    // Get number of services based on Company Totals active or not
    if (companyTotalsContext.active) {
      noOfServiceSelected = servicesFilterContext.getAllItems().length;
    } else {
      noOfServiceSelected = servicesFilterContext.getSelectedItems().length;
    }

    if (noOfServiceSelected === 0) {
      return 'None selected';
    } else return `${noOfServiceSelected} of ${totalServices} services selected`;
  };

  const getServiceFilterTooltip = () => {
    let servicesSelected;
    let serviceFilterAsString = '';

    // Get number of services based on Company Totals active or not
    if (companyTotalsContext.active) {
      servicesSelected = servicesFilterContext.getAllItems();
    } else {
      servicesSelected = servicesFilterContext.getSelectedItems();
    }

    servicesSelected.map((service) => {
      serviceFilterAsString = `${serviceFilterAsString} ${!serviceFilterAsString ? '' : ','} ${
        service.label
      } ${service.subLabel ? '(' + service.subLabel + ')' : ''}`;
    });

    if (!serviceFilterAsString) {
      return 'None selected';
    }
    return serviceFilterAsString;
  };

  const getAccountTypeLabel = (provider, numberofAccounts) => {
    switch (provider) {
      case 'AZURE':
        return numberofAccounts !== 1 ? 'Subscriptions' : 'Subscription';
      case 'AWS':
        return numberofAccounts !== 1 ? 'Accounts' : 'Account';
      default:
        return numberofAccounts !== 1 ? 'Resource Boundaries' : 'Resource Boundary';
    }
  };

  const getAccountIfMultipleAccount = () => {
    let account;
    let remainingNoOfAccounts;
    let displayNameRemainingAccounts;
    if (accountsFilterContext.isAllowingMultipleSelection) {
      // Protect during early loading or account being unselected
      if (!accountsFilterContext.getSelectedItems()?.length) {
        return;
      }
      account = customerAccountsContext.getAccountById(
        accountsFilterContext.getSelectedItems()[0].id
      );
      remainingNoOfAccounts = accountsFilterContext.getSelectedItems().length - 1;
      displayNameRemainingAccounts = getAccountTypeLabel(
        providersFilterContext.getSelectedItem()?.id,
        remainingNoOfAccounts
      ).toLowerCase();
    } else {
      // Protect during early loading or account being unselected
      if (!accountsFilterContext.getSelectedItems()) {
        return;
      }
      account = customerAccountsContext.getAccountById(accountsFilterContext.getSelectedItems().id);
      remainingNoOfAccounts = 0;
    }
    return { account, remainingNoOfAccounts, displayNameRemainingAccounts };
  };

  const getViewableAccountAlias = () => {
    const data = getAccountIfMultipleAccount();
    if (!data) return '-';
    if (data.account.Alias) {
      return data.remainingNoOfAccounts
        ? `${data.account.Alias} + ${data.remainingNoOfAccounts} ${data.displayNameRemainingAccounts}`
        : data.account.Alias;
    } else {
      return data.remainingNoOfAccounts
        ? `${data.account.AccountId} + ${data.remainingNoOfAccounts} ${data.displayNameRemainingAccounts}`
        : data.account.AccountId;
    }
  };

  const getViewableAccountId = () => {
    const data = getAccountIfMultipleAccount();
    if (!data) return '-';
    if (data.account.Alias) {
      return data.remainingNoOfAccounts
        ? `(${data.account.AccountId} + ${data.remainingNoOfAccounts} ${data.displayNameRemainingAccounts})`
        : `(${data.account.AccountId})`;
    } else {
      return null;
    }
  };

  return (
    <>
      <div className={'tds-container-no-padding'}>
        <div className={'tds-row'}>
          <div
            className={
              'tds-col-xs-12 tds-col-sm-6 tds-col-md-6 tds-col-lg-6 tds-col-xlg-3 tds-u-mt1'
            }>
            <TdsCard>
              <div slot={'header'}>
                {getAccountTypeLabel(
                  providersFilterContext.getSelectedItem()?.id,
                  accountsFilterContext.getSelectedItem()?.length
                )}
              </div>
              <div slot={'body'}>
                {!companyTotalsContext.active ? (
                  <div title={getAccountsTooltip()}>
                    <p className={blurSensitive ? 'blur' : ''}>{getViewableAccountAlias()}</p>
                    <p className={blurSensitive ? 'blur' : ''}>
                      {getViewableAccountId() || '\u00a0'}
                    </p>
                  </div>
                ) : (
                  <>
                    <p>Company Total Emissions</p>
                    <p className={blurSensitive ? 'blur' : ''}>
                      {providersFilterContext.getSelectedItems()?.id === 'AZURE' ? (
                        'All available Azure subscriptions'
                      ) : providersFilterContext.getSelectedItems()?.id === 'AWS' ? (
                        'All available AWS accounts'
                      ) : (
                        <>&nbsp;</>
                      )}
                    </p>
                  </>
                )}
              </div>
            </TdsCard>
          </div>
          <div
            className={
              'tds-col-xs-12 tds-col-sm-6 tds-col-md-6 tds-col-lg-6 tds-col-xlg-3 tds-u-mt1'
            }>
            <TdsCard>
              <div slot={'header'}>Services</div>
              <div slot={'body'}>
                <p aria-label={getServiceFilterTooltip()}>{getNumberOfServices()}</p>
                <p>&nbsp;</p>
              </div>
            </TdsCard>
          </div>
          <div
            className={
              'tds-col-xs-12 tds-col-sm-6 tds-col-md-6 tds-col-lg-6 tds-col-xlg-3 tds-u-mt1'
            }>
            <TdsCard>
              <div slot={'header'}>{'Total CO\u2082e / Energy'}</div>
              <div slot={'body'}>
                {adjustMagnitude(props.carbon) +
                  ' ' +
                  getCarbonUnitLabel(props.carbon) +
                  ' / ' +
                  adjustMagnitude(props.energy) +
                  ' ' +
                  getEnergyUnitLabel(props.energy)}
                <DatePeriod from={props.filteredDateRangeFrom} to={props.filteredDateRangeTo} />
              </div>
            </TdsCard>
          </div>
          <div
            className={
              'tds-col-xs-12 tds-col-sm-6 tds-col-md-6 tds-col-lg-6 tds-col-xlg-3 tds-u-mt1'
            }>
            <TdsCard>
              <div slot={'header'}>{'Carbon Intensity'}</div>
              <div slot={'body'}>
                {props.carbon
                  ? ((1000 * props.carbon) / props.energy).toFixed(0) + 'g CO\u2082e per kWh'
                  : '-'}
                <p>&nbsp;</p>
              </div>
            </TdsCard>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChartOverview;
