import React, { useContext, useEffect, useState } from 'react';
import TrendsTable from './TrendsTable';
import TrendsFilterContext from '../../../contexts/Trends/TrendsFilterContext';
import { AggregatedContext } from '../../../contexts/Emissions/AggregatedEmissionsContext';
import { getDateString } from '../../../utilities/Datestring';
import { calculateCategoryTotal } from '../../../utilities/TrendsUtility';
import ProvidersFilterContext from '../../../contexts/FilterContexts/ProvidersFilterContext';
import AccountsFilterContext from '../../../contexts/FilterContexts/AccountsFilterContext';
import CategoriesFilterContext from '../../../contexts/FilterContexts/CategoriesFilterContext';

const TrendsData = () => {
  const trendsFilterContext = useContext(TrendsFilterContext);
  const aggregatedEmissionsContext = useContext(AggregatedContext);
  const providerContext = useContext(ProvidersFilterContext);
  const accountsContext = useContext(AccountsFilterContext);
  const categoriesContext = useContext(CategoriesFilterContext);
  const [trendsAverageData, setAverageTrendsData] = useState(null);

  // Clear data if PROVIDER or ACCOUNTS deselected
  useEffect(() => {
    let providerSelected = providerContext.getSelectedItem();
    let accountsSelected = accountsContext.getSelectedItems();

    if (!providerSelected || accountsSelected.length === 0) {
      setAverageTrendsData(null);
    }
  }, [providerContext.selectedItems, accountsContext.selectedItems]);

  // Start fetching data for BASELINE (if PROVIDER and ACCOUNTS selected)
  useEffect(() => {
    let providerSelected = providerContext.getSelectedItem();
    let accountsSelected = accountsContext.getSelectedItems();

    if (providerSelected && accountsSelected.length > 0) {
      aggregatedEmissionsContext.getAggregatedData(
        providerSelected.id,
        accountsSelected.map((account) => account.id),
        getDateString(trendsFilterContext.baseLineDate[0].startDate),
        getDateString(trendsFilterContext.baseLineDate[0].endDate),
        getDateString(trendsFilterContext.assessmentDate[0].startDate),
        getDateString(trendsFilterContext.assessmentDate[0].endDate)
      );
    }
  }, [
    accountsContext.selectedItems,
    trendsFilterContext.baseLineDate[0].startDate,
    trendsFilterContext.baseLineDate[0].endDate,
    trendsFilterContext.assessmentDate[0].startDate,
    trendsFilterContext.assessmentDate[0].endDate
  ]);

  // Start calculating data if BASELINE and ASSESSMENT is completely fetched or CATEGORIES selection change
  useEffect(() => {
    // Leave if any of the context are still calling the API
    if (aggregatedEmissionsContext.isApiCall) {
      return;
    }

    // Summarize and calculate average data if data is present
    if (aggregatedEmissionsContext.aggregatedData) {
      let finalCategoryData = calculateCategoryTotal(
        aggregatedEmissionsContext.aggregatedData,
        categoriesContext.getSelectedItems()
      );

      setAverageTrendsData(finalCategoryData);
    }
  }, [aggregatedEmissionsContext.isApiCall, categoriesContext.selectedItems]);

  return (
    <>
      <TrendsTable
        trendsAverageData={trendsAverageData}
        isLoading={aggregatedEmissionsContext.isApiCall}
      />
    </>
  );
};

export default TrendsData;
