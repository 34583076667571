import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { TdsSpinner } from '@scania/tegel-react';

function CategoriesChart(props) {
  const [categoryEmissions, setCategoryEmissions] = useState([]);
  const options = {
    chart: {
      width: '100%',
      type: 'donut',
      id: 'categories-donut',
      toolbar: {
        show: false
      }
    },
    labels: ['Compute', 'Memory', 'Storage', 'Network', 'Embodied'],
    legend: {
      onItemClick: {
        toggleDataSeries: true
      },
      position: 'bottom'
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'start'
    },
    tooltip: {
      y: {
        formatter: (v) => {
          return v.toFixed(0) + 'g';
        }
      }
    }
  };

  useEffect(() => {
    // Get first Region element for each item in props.data
    if (props.data) {
      // Make shallow copy of props.data to avoid mutating the original data
      let data = [...props.data];
      // sort the data by TotalCarbon in descending order
      const initialValue = {
        CarbonCompute: 0,
        CarbonMemory: 0,
        CarbonStorage: 0,
        CarbonNetwork: 0,
        CarbonEmbodied: 0
      };
      const totals = data.reduce((accumulator, currentValue) => {
        return {
          CarbonCompute: accumulator.CarbonCompute + currentValue.CarbonCompute,
          CarbonMemory: accumulator.CarbonMemory + currentValue.CarbonMemory,
          CarbonStorage: accumulator.CarbonStorage + currentValue.CarbonStorage,
          CarbonNetwork: accumulator.CarbonNetwork + currentValue.CarbonNetwork,
          CarbonEmbodied: accumulator.CarbonEmbodied + currentValue.CarbonEmbodied
        };
      }, initialValue);
      let totalsAsList = [
        totals.CarbonCompute,
        totals.CarbonMemory,
        totals.CarbonStorage,
        totals.CarbonNetwork,
        totals.CarbonEmbodied
      ];
      setCategoryEmissions(totalsAsList);
    }
  }, [props.data]);

  return (
    <>
      <div className={'tds-container'}>
        {props.data.length > 0 ? (
          <div className={'position-absolute'} style={{ height: '20rem' }}>
            <Chart
              height="100%"
              width="100%"
              options={options}
              series={categoryEmissions}
              type={'donut'}></Chart>
          </div>
        ) : (
          <div
            className={'position-absolute'}
            style={{ height: '20rem', display: 'grid', placeItems: 'center' }}>
            <TdsSpinner size={'lg'} />
          </div>
        )}
      </div>
    </>
  );
}

export default CategoriesChart;
