import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PermissionsUsersList from './PermissionsContent/PermissionsUsersList';
import PermissionsAccountActions from './PermissionsContent/PermissionsAccountActions';
import UserContext from '../../contexts/Auth/UserContext';
import CustomersContext from '../../contexts/Customer/CustomersContext';
import HeaderWithSubText from '../atoms/Typography/HeaderWithSubText';

function Permissions() {
  const [isActive, setIsActive] = useState({ status: false, selectedUser: null });
  const userContext = useContext(UserContext);
  const customerContext = useContext(CustomersContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      userContext.adminCustomerIds &&
      customerContext.activeCustomer &&
      !userContext.adminCustomerIds.includes(customerContext.activeCustomer)
    ) {
      navigate('/settings/mypermissions');
    }
  }, []);
  return (
    <div className={'tds-container tds-u-mt3 tds-u-mb3'}>
      <div className="tds-row">
        <div className={'tds-col'}>
          <HeaderWithSubText text="Manage permissions" subText="Edit permissions for users" />{' '}
        </div>
      </div>
      <hr />
      <div className={'tds-row '}>
        <div
          className={
            'tds-col tds-col-xs-12 tds-col-md-4 permission-body-users tds-show-md tds-hide-xs'
          }>
          <PermissionsUsersList setIsActive={setIsActive} isActive={isActive} />
        </div>
        <div className={'tds-col tds-col-md tds-show-md tds-hide-xs'}>
          <PermissionsAccountActions isActive={isActive} />
        </div>
      </div>
    </div>
  );
}

export default Permissions;
