// import RequiresAuth from '../common/Auth/RequiresAuth';
import React, { useContext, useEffect, useState } from 'react';
import ScaniaHeaderAuth from './ScaniaHeaderAuth';
import ScaniaHeaderNoAuth from './ScaniaHeaderNoAuth';
import AuthContext from '../../contexts/common/AuthContext';
import { app_path_prefix } from '../../config';
// import React, { useContext } from 'react';

// import UserContext from '../../contexts/Auth/UserContext';

function ScaniaHeader() {
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const auth = useContext(AuthContext);

  useEffect(() => {
    const getPathnameWithoutPrefix = (prefix) => {
      let pathname = window.location.pathname;
      return pathname.startsWith(`/${prefix}/`)
        ? pathname.replace('/', '').replace(`${prefix}/`, '')
        : pathname.replace('/', '');
    };

    // Set initial menu item to become active
    let locationPathname = getPathnameWithoutPrefix(app_path_prefix);
    // If pathname is at root set emissions as active menu by default
    if (locationPathname === '') {
      locationPathname = 'emissions';
    }
    setActiveMenuItem(locationPathname.toLowerCase());
  }, [activeMenuItem]);

  return <div>{auth.isLoggedIn() ? <ScaniaHeaderAuth /> : <ScaniaHeaderNoAuth />}</div>;
}

export default ScaniaHeader;
