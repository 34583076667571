import React, { createContext, useContext, useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import CustomersContext from '../Customer/CustomersContext';

export const RegionalEmissionsContext = createContext();

function getDate30DaysAgo() {
  let dt = new Date();
  dt.setDate(dt.getDate() - 30);
  return dt.toISOString().split('T')[0];
}

function getTodaysDate() {
  let dt = new Date();
  return dt.toISOString().split('T')[0];
}

export const RegionalEmissionsProvider = (props) => {
  const customerContext = useContext(CustomersContext);

  const [rawData, setRawData] = useState(undefined);
  // const [data, setData] = useState({});
  const [isLoadingData, setIsLoadingData] = useState(false);

  useEffect(() => {
    setIsLoadingData(true);
    async function fetchRegionalEmissions() {
      if (customerContext.activeCustomer) {
        return API.post(`emissions`, `/v1/data/`, {
          body: {
            Period: {
              Start: getDate30DaysAgo(),
              End: getTodaysDate()
            },
            Providers: ['AWS'],
            Customers: [customerContext.activeCustomer],
            PeriodGranularity: 'Daily',
            GroupBy: ['REGION']
          }
        })
          .then((resp) => {
            setRawData(resp);
          })
          .finally(() => {
            setIsLoadingData(false);
          });
      }
    }
    fetchRegionalEmissions();
  }, [customerContext.activeCustomer]);

  const state = {
    data: rawData,
    isLoadingData: isLoadingData
  };

  return (
    <RegionalEmissionsContext.Provider value={state}>
      {props.children}
    </RegionalEmissionsContext.Provider>
  );
};
export default RegionalEmissionsContext;
