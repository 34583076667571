export const getElementCoords = (element) => {
  if (element === null || element === undefined) {
    return null;
  }

  var box = element.getBoundingClientRect();
  var body = document.body;
  var docEl = document.documentElement;

  var clientTop = docEl.clientTop || body.clientTop || 0;
  var clientLeft = docEl.clientLeft || body.clientLeft || 0;
  var clientBottom = docEl.clientBottom || body.clientBottom || 0;
  var clientRight = docEl.clientRight || body.clientRight || 0;

  return {
    top: Math.round(box.top - clientTop),
    left: Math.round(box.left - clientLeft),
    bottom: Math.round(box.bottom - clientBottom),
    right: Math.round(box.right - clientRight)
  };
};
