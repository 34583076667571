import MainHeader from '../../components/atoms/Typography/MainHeader';
import DivTdsBody01 from '../../components/atoms/Typography/DivTdsBody01';
import React from 'react';

function About() {
  return (
    <div className={'tds-container'}>
      <div className={'tds-row'}>
        <div className={'tds-col'}>
          <MainHeader>About Cloud Emissions Tracker</MainHeader>
          <DivTdsBody01>
            Cloud Emissions Tracker (CET) is designed to help you measure, track and manage the
            carbon footprint of your cloud operations. CET integrates with AWS and Azure to collect
            usage data, and estimates the greenhouse gas emissions for your infrastructure and
            operations using regional emissions factors,
          </DivTdsBody01>
          <DivTdsBody01>
            The latest update to our calculation model includes more services and consider more
            aspects of your cloud server usage, such as storage, memory and network along with the
            embodied emissions.
          </DivTdsBody01>
        </div>
      </div>
    </div>
  );
}

export default About;
1;
