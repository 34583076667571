import React from 'react';
import { subDays } from 'date-fns';
import './DateSelection.css';

const DateSelection = ({ id, selectedDates, setSelectedDates, setDisable, days, text }) => {
  const handleOnClick = () => {
    if (id === 'baseline') {
      setSelectedDates([
        {
          ...selectedDates[0],
          startDate: subDays(new Date(), 2 * days),
          endDate: subDays(new Date(), days + 1)
        }
      ]);
    } else {
      setSelectedDates([
        {
          ...selectedDates[0],
          startDate: subDays(new Date(), days),
          endDate: subDays(new Date(), 1)
        }
      ]);
    }
    setDisable(false);
  };
  return (
    <div className="filter-drop-down-calender-fixed-date" onClick={handleOnClick}>
      {text}
    </div>
  );
};

export default DateSelection;
