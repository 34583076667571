import React from 'react';
import { TdsNavigationTab, TdsNavigationTabs } from '@scania/tegel-react';
import MyPermissions from './MyPermissions';
import ApiKeySettings from './ApiKeySettings';
import GeneralSettings from './GeneralSettings';
import { Link, Route, Routes } from 'react-router-dom';
import ManagePermissions from './ManagePermissions';

function Settings() {
  return (
    <>
      <div className="settings-permissions-container">
        <TdsNavigationTabs defaultSelectedIndex={0}>
          <TdsNavigationTab>
            <Link to="">My Permissions</Link>
          </TdsNavigationTab>
          <TdsNavigationTab>
            <Link to="permissions">Manage Permissions</Link>
          </TdsNavigationTab>
          <TdsNavigationTab>
            <Link to="apikey">Manage API Keys</Link>
          </TdsNavigationTab>
          <TdsNavigationTab>
            <Link to="general">General</Link>
          </TdsNavigationTab>
        </TdsNavigationTabs>
        <div className={'tds-container'}>
          <div className={'tds-row'}>
            <div className={'tds-col'}>
              <Routes>
                <Route index element={<MyPermissions />} />
                <Route path="permissions" element={<ManagePermissions />} />
                <Route path="apikey" element={<ApiKeySettings />} />
                <Route path="general" element={<GeneralSettings />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="settings-container-small-screen">
        <MobileViewScreen />
      </div> */}
      {/* <Container className="settings-permissions-container h-100">
        <Row className="h-100">
          <Col md={2} className="square border-end ">
            <SettingsSidePanel />
          </Col>
          <Col md={10}>
            <CustomerUsersProvider>
              <UserPermissionsProvider>
                <Permissions />
              </UserPermissionsProvider>
            </CustomerUsersProvider>
          </Col>
        </Row>
      </Container>
      <div className="settings-container-small-screen">
        <MobileViewScreen />
      </div> */}
    </>
  );
}

export default Settings;
