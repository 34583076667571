import React, { createContext, useContext, useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import CustomersContext from '../Customer/CustomersContext';
import AccountsFilterContext from '../FilterContexts/AccountsFilterContext';
import ProvidersFilterContext from '../FilterContexts/ProvidersFilterContext';
import ServicesFilterContext from '../FilterContexts/ServicesFilterContext';
import CategoriesFilterContext from '../FilterContexts/CategoriesFilterContext';
import DateFilterContext from '../FilterContexts/DateFilterContext';
import { fillMissingDates, getCarbonForCategories } from '../../utilities/EmissionsCalculations';

export const EmissionsContext = createContext();

export const EmissionsProvider = (props) => {
  const customerContext = useContext(CustomersContext);
  const providerContext = useContext(ProvidersFilterContext);
  const accountsContext = useContext(AccountsFilterContext);
  const servicesContext = useContext(ServicesFilterContext);
  const categoriesContext = useContext(CategoriesFilterContext);
  const dateFilterContext = useContext(DateFilterContext);

  const [rawData, setRawData] = useState(undefined);
  const [data, setData] = useState({});
  const [isLoadingData, setIsLoadingData] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const providerSelected = providerContext.getSelectedItem();
    const accountsSelected = accountsContext.getSelectedItems();
    const servicesSelected = servicesContext.getSelectedItems();

    let accountId = undefined;
    // Handle that Account Filter can be both single and multi selection based on provider
    if (accountsSelected instanceof Array && accountsSelected.length > 0) {
      accountId = accountsSelected[0].id;
    } else if (accountsSelected !== undefined) {
      accountId = accountsSelected.id;
    }

    // Set loading state
    setIsLoadingData(true);

    async function fetchEmissions() {
      if (accountId && providerSelected && servicesSelected.length > 0) {
        // Send a request for each selected service

        const provider = providerSelected.id;

        let accounts;
        if (accountsSelected instanceof Array && accountsSelected.length > 0) {
          accounts = accountsSelected.map((account) => account.id);
        } else {
          accounts = [accountId];
        }

        return API.post(`emissions`, `/v1/data/`, {
          body: {
            Period: {
              Start: dateFilterContext.startDateUtc,
              End: dateFilterContext.endDateUtc
            },
            Customers: [customerContext.activeCustomer],
            Providers: [provider],
            PeriodGranularity: 'Daily',
            Accounts: accounts,
            Services: servicesSelected.map((service) => service.id),
            GroupBy: ['PERIOD']
          }
        })
          .then((resp) => {
            // TODO: When API response comes, start by validating filters so that active selections are the same as when API request was made. If not, just throw away response since it's no longer valid.

            calculateCarbon(resp, isMounted);
            setRawData(resp);
          })
          .finally(() => {
            setIsLoadingData(false);
          });
      } else {
        setRawData(undefined);
        calculateCarbon(undefined, true);
        setIsLoadingData(false);
      }
      return () => {
        isMounted = false;
      };
    }
    fetchEmissions();
  }, [
    accountsContext.selectedItems,
    servicesContext.selectedItems,
    dateFilterContext.startDateUtc,
    dateFilterContext.endDateUtc
  ]);

  //useEffect to redo the categories calculation on changing actegories filter
  useEffect(() => {
    if (rawData) {
      calculateCarbon(rawData, true);
    }
  }, [categoriesContext.selectedItems]);

  const calculateCarbon = (apiResponse, isMounted) => {
    if (apiResponse) {
      fillMissingDates(apiResponse);
      // sort the data when not in correct date order
      apiResponse.Data?.sort((a, b) => new Date(a.Period) - new Date(b.Period));
    }
    let responseData = { Labels: [], Carbon: [], Energy: [] };
    apiResponse &&
      apiResponse.Data?.forEach(function (item) {
        if (apiResponse.PeriodGranularity === 'Daily') {
          responseData.Labels.push(item.Period);
        }
        const categoriesSelected = categoriesContext.getSelectedItems();
        const carbonForcategories = getCarbonForCategories(item, categoriesSelected);
        responseData.Carbon.push(carbonForcategories);
        responseData.Energy.push(item.Energy);
      });
    if (responseData && isMounted) {
      setData(responseData);
    } else {
      setData({});
    }
  };

  const state = {
    data: data,
    isLoadingData: isLoadingData
  };

  return <EmissionsContext.Provider value={state}>{props.children}</EmissionsContext.Provider>;
};
export default EmissionsContext;
