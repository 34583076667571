import { TdsNavigationTab, TdsNavigationTabs } from '@scania/tegel-react';
import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import AwsIntegration from './Components/AwsIntegration';
import AzureIntegration from './Components/AzureIntegration';

const Integration = () => {
  return (
    <div>
      <TdsNavigationTabs defaultSelectedIndex={0}>
        <TdsNavigationTab>
          <Link to={'aws'}>AWS Integration</Link>
        </TdsNavigationTab>
        <TdsNavigationTab>
          <Link to={'azure'}>Azure Integration</Link>
        </TdsNavigationTab>
      </TdsNavigationTabs>

      <div className={'tds-container'}>
        <div className={'tds-row'}>
          <div className={'tds-col'}>
            <Routes>
              <Route path="aws" element={<AwsIntegration />} />
              <Route path={'azure'} element={<AzureIntegration />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integration;
