function LiWithTitle({ title, children }) {
  return (
    <li>
      <div className={'tds-body-01'}>
        <b>{title}</b>
      </div>
      <div className={'tds-body-01'}>{children}</div>
    </li>
  );
}

export default LiWithTitle;
