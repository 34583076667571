import React, { useContext } from 'react';
import { useCookies } from 'react-cookie';
import CookieContext from '../../contexts/Cookies/CookieContext';
import './CookieDisclaimer.css';
import logger from '../../logger';
import { TdsButton } from '@scania/tegel-react';
import CookieSettingsModal from './CookiesModal/CookieSettingsModal';

const scania_privacy_policy =
  'https://www.scania.com/group/en/home/admin/misc/privacy-statement.html';
const scania_cookie_policy =
  'https://www.scania.com/group/en/home/admin/misc/privacy-statement/cookies.html';

function CookieDisclaimer() {
  const cookieContext = useContext(CookieContext);
  const [cookies, setCookie] = useCookies(['FunctionalCookieConsent']);

  const handleAccept = () => {
    cookieContext.setIsCookieConsent(true);
    cookieContext.setFunctionalCookies(true);
    setCookie('FunctionalCookieConsent', true, {
      path: '/',
      maxAge: 15768000
    });
    setCookie('CETCookieConsentApp', true, {
      path: '/',
      maxAge: 15768000
    });
    // work around for page not updating on accepting the cookies
    if (
      localStorage.getItem('SavedFilterSelections') ||
      localStorage.getItem('SavedFilterFavourites') ||
      localStorage.getItem('favouriteAccounts')
    ) {
      window.location.reload();
    }
  };

  const handleReject = () => {
    cookieContext.setIsCookieConsent(true);
    setCookie('CETCookieConsentApp', true, {
      path: '/',
      maxAge: 15768000
    });
  };
  logger(cookies);

  return (
    !cookieContext.IsCookieConsent && (
      <div className="w-100 cookie-disclaimer tds-text-blue-900">
        We use cookies to provide necessary website functionality and to improve your experience. By
        clicking “I Accept”, you are agreeing to give your consent to all cookies being used. You
        can also manage your cookies by clicking the “Cookie settings” and selecting the categories
        you’d like to accept. For a more detailed explanation read our
        <a target="_blank" rel="noreferrer" href={scania_cookie_policy} className="underline">
          {' '}
          Cookies Policy
        </a>{' '}
        and{' '}
        <a target="_blank" rel="noreferrer" href={scania_privacy_policy} className="underline">
          Privacy Statement
        </a>{' '}
        <div className="d-flex flex-wrap gap-2 tds-u-mt3 justify-content-start">
          <TdsButton
            onClick={handleAccept}
            className="cookie-disclaimer-accept-button"
            text="I Accept"
            size="sm"></TdsButton>
          <TdsButton
            onClick={handleReject}
            className="cookie-disclaimer-reject-button"
            text="Reject All"
            size="sm"></TdsButton>
          <CookieSettingsModal />
        </div>
      </div>
    )
  );
}

export default CookieDisclaimer;
