import React from 'react';

function FilterItemControl(props) {
  return <>{props.children}</>;
}

// Set default properties
FilterItemControl.defaultProps = {
  onClick: () => {},
  shallBeDisplayed: () => {
    return true;
  }
};

export default FilterItemControl;
