import React from 'react';

const ChevronLeftIcon = (props) => {
  const getHeight = () => {
    return props.height ?? '24';
  };

  const getWidth = () => {
    return props.width ?? '24';
  };

  return (
    <svg
      width={getWidth()}
      height={getHeight()}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 18L9 12L15 6"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChevronLeftIcon;
