import React from 'react';
import DivTdsBody01 from '../../../components/atoms/Typography/DivTdsBody01';

function RecommendationsRelocation() {
  return (
    <React.Fragment>
      <DivTdsBody01>
        Prefer use data centers in countries with consistently low carbon intensity, preferably
        regions where emissions per kWh produced is lower than 50g {'CO\u2082e'}. Regional emissions
        can be found at sources such as{' '}
        <a href="https://app.electricitymap.org/map" rel="noreferrer" target="_blank">
          electricitymap.org
        </a>
        .
      </DivTdsBody01>
      <DivTdsBody01>
        Prefer data centers in countries or regions with cooler climate. The cooler the climate is,
        less energy is needed to keep the processors cool. Studies shows that it takes about 1.5
        times the energy to keep data centers cooled per kWh used from a server and that this number
        can be substantially reduced in cooler climates (European Commission, 2017).
      </DivTdsBody01>
      <p>To read more about different energy sources, go to:</p>
      <ul>
        <li>IPCC - &quot;Technology-specific Cost and Performance Parameters&quot;</li>
        <li>Wikipedia - &quot;Life-cycle greenhouse-gas emissions of energy sources&quot;</li>
      </ul>
    </React.Fragment>
  );
}

export default RecommendationsRelocation;
