import { getCarbonForCategories } from './EmissionsCalculations';

// import { intervalToDuration } from 'date-fns';
export const transformResponseData = (apiResponseData, carbonCategories) => {
  let account_map = {};
  apiResponseData.forEach((item) => {
    const carbonTotal = getCarbonForCategories(item, carbonCategories);
    let account_object = account_map[item.Account];
    if (!account_object) {
      account_map[item.Account] = {};
    }
    account_map[item.Account][item.Period] = {
      CarbonTotal: carbonTotal,
      Energy: item.Energy
    };
  });

  return Object.entries(account_map).map(([key, value]) => {
    let baselineTotalCarbon = value.BaselinePeriod?.CarbonTotal;
    let assessmentTotalCarbon = value.AssessmentPeriod?.CarbonTotal;
    return {
      averageCarbonBaseline: +baselineTotalCarbon,
      averageEnergyBaseline: +value.BaselinePeriod?.Energy,
      averageCarbonAssessment: +assessmentTotalCarbon,
      averageEnergyAssessment: +value.AssessmentPeriod?.Energy,
      AccountId: key
    };
  });
};

export const calculateCategoryTotal = (aggregatedData, categoriesSelected) => {
  return {
    StartDateUtcBaseline: aggregatedData.BaselinePeriod.Start,
    EndDateUtcBaseline: aggregatedData.BaselinePeriod.End,
    StartDateUtcAssessment: aggregatedData.AssessmentPeriod.Start,
    EndDateUtcAssessment: aggregatedData.AssessmentPeriod.End,
    Provider: aggregatedData.Providers[0],
    Data: transformResponseData(aggregatedData.Data, categoriesSelected)
  };
};
