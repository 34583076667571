import React, { Component } from 'react';
import logger from '../../logger';

export class FilterContextBase extends Component {
  constructor(props, childContextType, childContextName) {
    super(props);

    // Store reference to child context type and name
    this.childContextType = childContextType;
    this.childContextName = childContextName.toString();

    // Define default state
    this.state = {
      updated: 0, // Counter to track number of updates, MECHANIC/WORKAROUND for consumers to identify state-changes in list of objects

      filterId: undefined,
      handlers: {},
      items: [],
      originalItems: [],
      selectedItems: undefined,

      selectionRequireConfirmation: true,
      isAllowingMultipleSelection: true,
      isAllowingFavourites: false,
      isSortingEnabled: true,

      setFilterId: this.setFilterId,
      setHandlers: this.setHandlers,
      setSelectionRequireConfirmation: this.setSelectionRequireConfirmation,
      setIsAllowingMultipleSelection: this.setIsAllowingMultipleSelection,
      setIsAllowingFavourites: this.setIsAllowingFavourites,
      setIsSortingEnabled: this.setIsSortingEnabled,

      setItemSelection: this.setItemSelection,
      toggleAllItemsSelection: this.toggleAllItemsSelection,
      toggleFavouriteItem: this.toggleFavouriteItem,

      getItem: this.getItem,
      getSelectedItem: this.getSelectedItem,
      getItems: this.getItems,
      getAllItems: this.getAllItems,
      getSelectedItems: this.getSelectedItems,
      getFavouriteItems: this.getFavouriteItems,
      checkIsAllItemsSelectedIfAny: this.checkIsAllItemsSelectedIfAny,
      checkIsOnlyOriginalItemsSelected: this.checkIsOnlyOriginalItemsSelected,
      hasItemsOrIsLoading: this.hasItemsOrIsLoading,

      confirmSelection: this.confirmSelection,
      flagAsUpdated: this.setUpdated
    };
  }

  // Variables
  childContextType = undefined;
  childContextName = undefined;
  SAVED_FILTER_SELECTIONS_ID = 'SavedFilterSelections';
  SAVED_FILTER_FAVOURITES_ID = 'SavedFilterFavourites';
  SAVED_FILTER_FAVOURITES_LEGACY_ID = 'favouriteAccounts';

  // Mechanical Set Functions
  setUpdated = () => {
    this.setState((previousState) => ({ updated: previousState.updated + 1 }));
  };

  // Configurational Set Functions
  setFilterId = (value) => {
    this.setState({ filterId: value });
  };
  setHandlers = (controlId, value) => {
    this.setState((previousState) => {
      var handlers = previousState.handlers;
      // Store handlers per control id
      handlers[controlId] = value;
      return { handlers: handlers };
    });
  };
  setSelectionRequireConfirmation = (value) => {
    this.setState({ selectionRequireConfirmation: value });
  };
  setIsAllowingMultipleSelection = (value) => {
    this.setState({ isAllowingMultipleSelection: value });
  };
  setIsAllowingFavourites = (value) => {
    this.setState({ isAllowingFavourites: value });
  };
  setIsSortingEnabled = (value) => {
    this.setState({ isSortingEnabled: value });
  };

  // Business Logical Set Functions
  setItems = (value, loadFromSettings = true, updateOriginal = true) => {
    // If enabled, start by setting sort order
    if (this.state.isSortingEnabled === true) {
      value = this.sortAlphabetically(value, 'label', true);
    }

    // Load settings from localStorage
    if (loadFromSettings) {
      this.loadSavedFilterSettings(value);
    }

    // Store items
    this.setState({ items: value });

    // Perform a deep copy and store original items away
    if (updateOriginal) {
      this.setOriginalItems(JSON.parse(JSON.stringify(value)));
    }

    // Flag as updated
    this.setUpdated();
  };
  setOriginalItems = (value) => {
    this.setState({ originalItems: value });

    // store selected items since original items is set during initial load and on selection confirmed
    this.setState({ selectedItems: this.getSelectedItems(true) });
  };
  setItemSelection = (controlId, itemId, selectionState) => {
    // Create a copy of the items array
    const updatedItems = [...this.state.items];
    // If multiple selection is off, deselect all items
    if (!this.state.isAllowingMultipleSelection) {
      updatedItems.forEach((item) => {
        if (item.disabled !== true) {
          item.selected = false;
        }
      });
    }

    // Find the item and set its selection state (but not if disabled)
    const itemIndex = updatedItems.findIndex((i) => i.id === itemId && i.disabled !== true);
    if (itemIndex !== -1) {
      updatedItems[itemIndex].selected = selectionState;
    }
    // Update the state with the new items array
    this.setItems(updatedItems, false, false);
    // Confirm selection if context does not require explicit confirmation
    if (!this.state.selectionRequireConfirmation) {
      this.confirmSelection(controlId);
    }
  };

  toggleAllItemsSelection = () => {
    // Only allow this feature if multi-selection if allowed
    if (this.state.isAllowingMultipleSelection) {
      // Get and negate current selection state
      let newSelectionState = !this.checkIsAllItemsSelectedIfAny();

      // Create a new array with updated selected property
      const updatedItems = this.state.items.map((item) => {
        if (item.disabled !== true) {
          return { ...item, selected: newSelectionState };
        }
        return item;
      });

      // Save selections to state (update original and saved selections if confirmation not required)
      if (this.state.selectionRequireConfirmation) {
        this.setItems(updatedItems, false, false);
      } else {
        this.setItems(updatedItems, false, true);
        this.saveFilterSelection(this.state.items);
      }
    }
  };
  toggleFavouriteItem = (itemId) => {
    var item = this.state.items.find((i) => i.id === itemId);
    item.favourite = !item.favourite;
    this.setItems(this.state.items, false, false); // TODO: Original items should also be updated here.. but doing so generates bug with Apply Filter selection

    // Save favourite state to storage
    this.saveFilterFavourite(itemId, item.favourite);
  };

  // Business Logical Get Functions
  getItem = (itemId) => {
    return this.state.items.find((i) => i.id === itemId);
  };
  getSelectedItem = (ignoreSelectionRequirement = false) => {
    let items;
    // Handle flow where confirmation is NOT required (== rely on this.state.items)
    if (ignoreSelectionRequirement === true || !this.state.selectionRequireConfirmation) {
      items = this.state.items;
    }
    // Handle flow where confirmation IS required (== rely on this.state.originalItems)
    else {
      items = this.state.originalItems;
    }

    // Filter out all selected items and...
    let selectedItems = items.filter((i) => i.selected === true);

    // ...return a single item (if that is selected) or undefined if multiple items are selected
    return selectedItems.length === 1 ? selectedItems[0] : undefined;
  };
  getItems = () => {
    // Return...
    if (this.state.isAllowingFavourites) {
      // ...items that are not favourites (if that is enabled)
      return this.state.items.filter((i) => i.favourite !== true);
    }

    // ...otherwise ALL items
    return this.state.items;
  };
  getAllItems = () => {
    // Return ALL items
    return this.state.items;
  };
  getSelectedItems = (ignoreSelectionRequirement = false) => {
    let items;
    // Handle flow where confirmation is NOT required (== rely on this.state.items)
    if (ignoreSelectionRequirement === true || !this.state.selectionRequireConfirmation) {
      items = this.state.items;
    }
    // Handle flow where confirmation IS required (== rely on this.state.originalItems)
    else {
      items = this.state.originalItems;
    }

    // Check if multiple selection is allowed, based on that...
    if (!this.state.isAllowingMultipleSelection) {
      // ...return a single item (if that is selected) or undefined if multiple items are selected
      return this.getSelectedItem(ignoreSelectionRequirement);
    } else {
      // ...return a list of selected items
      return items.filter((i) => i.selected === true);
    }
  };
  getFavouriteItems = () => {
    return this.state.items.filter((i) => i.favourite === true);
  };
  checkIsAllItemsSelectedIfAny = () => {
    // Check if any item exist and if all items are marked as selected...
    if (this.state.items.length !== 0 && this.state.items.every((item) => item.selected === true)) {
      return true;
    }

    // ...otherwise return false
    return false;
  };
  checkIsOnlyOriginalItemsSelected = () => {
    // Return true if original items are selected ... otherwise false
    return this.checkIfSelectionMatchOriginal(this.state.items, this.state.originalItems);
  };
  hasItemsOrIsLoading = () => {
    if (this.state.items.length > 0 || this.state.isFetchingItems === true) {
      return true;
    }
    return false;
  };

  // Business Logical Supporting Functions
  triggerHandlers = (controlId) => {
    // Iterate all registered handlers and call them one by one (with selection as parameter)
    if (typeof this.state.handlers === typeof {}) {
      Object.entries(this.state.handlers).map(([handlerControlId, handlers]) => {
        // Only trigger handlers for the specific control id
        if (controlId === handlerControlId) {
          handlers.forEach((handler) => {
            handler(this.getSelectedItems(true));
          });
        }
      });
    }
  };
  confirmSelection = (controlId) => {
    // Save selection in localStorage
    this.saveFilterSelection(this.getSelectedItems(true));

    // Perform a deep copy and store original items away
    this.setOriginalItems(JSON.parse(JSON.stringify(this.state.items)));

    // Trigger all registered handlers
    this.triggerHandlers(controlId);
  };

  // Supporting Functions
  loadSavedFilterSettings = (itemsFromBackend) => {
    try {
      let filterId = this.state.filterId;
      let contextKey = this.childContextName;
      let savedSelections = this.isCookieConsent()
        ? JSON.parse(localStorage.getItem(this.SAVED_FILTER_SELECTIONS_ID))
        : null;
      let savedFavourites = this.isCookieConsent()
        ? JSON.parse(localStorage.getItem(this.SAVED_FILTER_FAVOURITES_ID))
        : null;
      // Handle LEGACY favourite storage if no NEW storage has been set (for accounts only)
      if (
        savedFavourites === null &&
        filterId === 'Emissions' &&
        contextKey === 'AccountsFilterContext'
      ) {
        // Get LEGACY object from storage
        let savedLegacyFavourites = JSON.parse(
          localStorage.getItem(this.SAVED_FILTER_FAVOURITES_LEGACY_ID)
        );

        // If LEGACY exists then parse out all "true" values and get them as string Array
        if (savedLegacyFavourites !== null) {
          let parsedLegacyFavourites = Object.entries(savedLegacyFavourites)
            .map(([key, value]) => {
              if (value) {
                return key;
              }
            })
            .filter((item) => item !== undefined);

          // Iterate all LEGACY favourite accounts and store them in NEW storage
          parsedLegacyFavourites.forEach((favouriteId) => {
            this.saveFilterFavourite(favouriteId, true);
          });

          // Read complete favourites from NEW storage again
          savedFavourites = JSON.parse(localStorage.getItem(this.SAVED_FILTER_FAVOURITES_ID));
        }
      }

      // Apply filter selections only if something has been previously stored, otherwise use any default selection from backend
      if (
        savedSelections &&
        filterId in savedSelections &&
        contextKey in savedSelections[filterId]
      ) {
        // Iterate all items, select where id is in localStorage and unselect otherwise
        itemsFromBackend.forEach((item) => {
          if (item.disabled !== true) {
            item.selected = savedSelections[filterId][contextKey].includes(item.id);
          }
        });
      }

      // Apply filter favourites only if something has been previously stored, otherwise use any default selection from backend
      if (
        savedFavourites &&
        filterId in savedFavourites &&
        contextKey in savedFavourites[filterId]
      ) {
        // Iterate all items, mark as favourite where id is in localStorage and mark as non-favourite otherwise
        itemsFromBackend.forEach((item) => {
          item.favourite = savedFavourites[filterId][contextKey].includes(item.id);
        });
      }
    } catch (error) {
      logger('ERRROR - loadSavedFilterSettings: ' + error);
    }
  };
  saveFilterSelection = (selection) => {
    try {
      // Just leave and don't save things to memory if cookies aren't consented
      if (!this.isCookieConsent()) {
        return;
      }

      let filterId = this.state.filterId;
      let contextKey = this.childContextName;
      let currentSelections = localStorage.getItem(this.SAVED_FILTER_SELECTIONS_ID);

      // Just leave if filterId has not yet been set
      if (filterId === undefined) {
        return;
      }

      // Check and retrieve any previous selections, otherwise create new dict
      if (currentSelections !== null) {
        currentSelections = JSON.parse(currentSelections);
      } else {
        currentSelections = {};
      }

      // Check if selections exists for this specific filter, otherwise create new dict
      if (!(filterId in currentSelections)) {
        currentSelections[filterId] = {};
      }

      // Check if selections exists for this specific context key, otherwise create new array
      if (!(contextKey in currentSelections[filterId])) {
        currentSelections[filterId][contextKey] = [];
      }

      let selectionsToAdd, selectionsToRemove, newSelections;

      // Interpret selection and original items to parse out what items to add and remove
      if (selection === undefined) {
        // Get items to add when nothing is selected == NOTHING
        selectionsToAdd = [];

        // Get items to remove when nothing is selected == ALL
        selectionsToRemove = this.state.originalItems;
      } else if (this.state.isAllowingMultipleSelection === true) {
        // Get items to add when multi seletion is allowed == same AS SELECTION
        selectionsToAdd = selection;

        // Get items to remove when multi seletion is allowed == all but NOT the SELECTED ITEMS
        selectionsToRemove = this.state.originalItems.filter((originalItem) =>
          selection.every((selectionItem) => originalItem.id !== selectionItem.id)
        );
      } else {
        // Get items to add when multi seletion is not allowed == same AS SELECTION (but as list)
        selectionsToAdd = [selection];

        // Get items to remove when multi seletion is not allowed == all but NOT the SELECTED ITEM
        selectionsToRemove = this.state.originalItems.filter(
          (originalItem) => originalItem.id !== selection.id
        );
      }

      // Only take the "id" property from the lists of items
      selectionsToAdd = selectionsToAdd.map((item) => {
        return item.id;
      });
      selectionsToRemove = selectionsToRemove.map((item) => {
        return item.id;
      });

      // Merge the newly calculated items to add and remove with any existing/additional items already in storage
      newSelections = currentSelections[filterId][contextKey];
      selectionsToRemove.forEach(
        (itemId) => (newSelections = newSelections.filter((newItemId) => newItemId !== itemId))
      );
      selectionsToAdd.forEach((itemId) => {
        if (!newSelections.includes(itemId)) {
          newSelections.push(itemId);
        }
      });
      currentSelections[filterId][contextKey] = newSelections;

      // Overwrite any previous selections for this context and save as JSON string
      localStorage.setItem(this.SAVED_FILTER_SELECTIONS_ID, JSON.stringify(currentSelections));
    } catch (error) {
      logger('ERRROR - saveFilterSelection: ' + error);
    }
  };
  saveFilterFavourite = (itemId, setAsfavourite) => {
    try {
      // Just leave and don't save things to memory if cookies aren't consented
      if (!this.isCookieConsent()) {
        return;
      }

      let filterId = this.state.filterId;
      let contextKey = this.childContextName;
      let currentFavourites = localStorage.getItem(this.SAVED_FILTER_FAVOURITES_ID);

      // Just leave if filterId has not yet been set
      if (filterId === undefined) {
        return;
      }

      // Check and retrieve any previous favourites, otherwise create new dict
      if (currentFavourites !== null) {
        currentFavourites = JSON.parse(currentFavourites);
      } else {
        currentFavourites = {};
      }

      // Check if favourites exists for this specific filter, otherwise create new dict
      if (!(filterId in currentFavourites)) {
        currentFavourites[filterId] = {};
      }

      // Check if favourites exists for this specific context key, otherwise create new list
      if (!(contextKey in currentFavourites[filterId])) {
        currentFavourites[filterId][contextKey] = [];
      }

      // If filter should be stored...
      if (setAsfavourite) {
        // ...append to storage if not already there
        if (!currentFavourites[filterId][contextKey].includes(itemId)) {
          currentFavourites[filterId][contextKey].push(itemId);
        }
      } else {
        // ...if not, then ensure it's removed from storage
        if (currentFavourites[filterId][contextKey].includes(itemId)) {
          currentFavourites[filterId][contextKey] = currentFavourites[filterId][contextKey].filter(
            (id) => id !== itemId
          );
        }
      }

      // Overwrite any previous selections for this context and save as JSON string
      localStorage.setItem(this.SAVED_FILTER_FAVOURITES_ID, JSON.stringify(currentFavourites));
    } catch (error) {
      logger('ERRROR - saveFilterFavourite: ' + error);
    }
  };

  checkIfSelectionMatchOriginal = (updatedItems, originalItems) => {
    // Take out only selected items and sort them based on "id"
    let updatedItemsSorted = updatedItems
      .filter((i) => i.selected === true)
      .sort((i1, i2) => {
        return i1.id.localeCompare(i2.id);
      });
    let originalItemsSorted = originalItems
      .filter((i) => i.selected === true)
      .sort((i1, i2) => {
        return i1.id.localeCompare(i2.id);
      });

    // Check that array lengthes are equal ... otherwise return false
    if (updatedItemsSorted.length !== originalItemsSorted.length) {
      return false;
    }

    // Iterate all items and see if "id" match identically ... if so return true, otherwise return false
    return updatedItemsSorted.every((item, index) => {
      return item.id === originalItemsSorted[index].id;
    });
  };

  sortAlphabetically = (listToSort, propertyName, ascending = true) => {
    // Define the sorting logic
    let sortFunction = (propertyName, ascending) => {
      let ensureString = (value) => {
        return typeof value === 'string' || value instanceof String ? value : '';
      };

      return (first, second) => {
        let firstParsed = ensureString(first[propertyName]).toLowerCase();
        let secondParsed = ensureString(second[propertyName]).toLowerCase();

        if (ascending) {
          return firstParsed.localeCompare(secondParsed);
        } else {
          return secondParsed.localeCompare(firstParsed);
        }
      };
    };

    // Perform the actual sort
    return listToSort.sort(sortFunction(propertyName, ascending));
  };

  isCookieConsent = () => {
    const cookies = Object.fromEntries(
      document.cookie.split(';').map((cookie) => cookie.trim().split('='))
    );
    return Object.keys(cookies).includes('FunctionalCookieConsent')
      ? cookies['FunctionalCookieConsent'].toLowerCase() === 'true'
      : false;
  };

  // React Functions
  render() {
    return React.createElement(
      this.childContextType.Provider,
      { value: this.state },
      this.props.children
    );
  }
}

export default FilterContextBase;
